import { gql } from "@apollo/client";
import { GraphqlService } from "../../graphql.service";
import { Cardconnect } from "../cardconnect";

export const CREATE_CARDCONNECT = gql`
  ${Cardconnect.FRAGMENT}
  mutation ($business_id: Int!, $mid: Int, $note: String) {
    create_cardconnect(business_id: $business_id, mid: $mid, note: $note) {
      ...create_cardconnect_Fields
    }
  }
`;

export function create_cardconnect(input: {
  business_id: number;
  mid: number;
  note: String;
}): Promise<Cardconnect> {
  return new Promise<Cardconnect>(async (resolve, reject) => {
    try {
      let res = await GraphqlService.SendMutation(CREATE_CARDCONNECT, input);
      resolve(res);
    } catch (ex: any) {
      console.log("Error in create_cardconnect: ", ex.message);
      reject({ message: ex.message });
    }
  });
}
