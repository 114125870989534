import { DocumentNode, gql } from "@apollo/client";
import { Business } from "../business/index";
import { itemnameSP, itemInput } from "../cardconnect_terminal/index";
import { Cardconnect_transaction } from "../cardconnect_transaction/index";

export class Invoice_item {
  id?: number;
  invoice_id?: number;
  name: string = "";
  tax_amount: number = 0;
  base_amount: number = 0;
  amount: number = 0;
  note: string = "";
  transaction_id?: number;
  cardconnect_transaction: Cardconnect_transaction =
    new Cardconnect_transaction();
  count: number = 0;
  reference: string = "";

  static FRAGMENT(includeCardconnectTransaction: boolean = false): DocumentNode {
    if (includeCardconnectTransaction) {
      return gql`
        fragment Transaction_invoice_Fields on Transaction_invoice {
          id
          invoice_id
          name
          tax_amount
          base_amount
          amount
          note
          transaction_id
          cardconnect_transaction {
            ...Cardconnect_transaction_Fields
          }
          count
          reference
        }
        ${Cardconnect_transaction.FRAGMENT}`
    } else {
      return gql`
        fragment Transaction_invoice_Fields on Transaction_invoice {
          id
          invoice_id
          name
          tax_amount
          base_amount
          amount
          note
          transaction_id
          count
          reference
        }`
    }
  }
}

export class Transaction_invoice {
  id?: number;
  order_reference: string = "";
  items: itemnameSP[] = [];
  phone: string = "";
  email: string = "";
  name: string = "";
  business: Business = new Business();
  token: string = "";
  cardconnect_transactions: Cardconnect_transaction[] = [];
  note: string = "";
  invoice_items: Invoice_item[] = [];
  amount: number = 0;
  time: string = "";
  amount_remaining: number = 0;

  static FRAGMENT(includeCardconnectTransaction: boolean = false): DocumentNode {
    if (includeCardconnectTransaction){
      return gql`
        fragment Transaction_invoice_Fields on Transaction_invoice {
          id
          order_reference
          items {
            ...itemnameSP_Fields
          }
          phone
          email
          name
          business {
            ...Business_Fields
          }
          token
          cardconnect_transactions {
            ...Cardconnect_transaction_Fields
          }
          note
          invoice_items {
            ...Invoice_item_Fields
          }
          amount
          time
          amount_remaining
        }
        ${itemnameSP.FRAGMENT}
        ${Business.FRAGMENT}
        ${Cardconnect_transaction.FRAGMENT}
        ${Invoice_item.FRAGMENT()}`
        }
    else{
      return gql`
        fragment Transaction_invoice_Fields on Transaction_invoice {
          id
          order_reference
          items {
            ...itemnameSP_Fields
          }
          phone
          email
          name
          business {
            ...Business_Fields
          }
          token
          note
          invoice_items {
            ...Invoice_item_Fields
          }
          amount
          time
          amount_remaining
        }
        ${itemnameSP.FRAGMENT}
        ${Business.FRAGMENT}
        ${Invoice_item.FRAGMENT()}`
        }
  }
}

export class Add_transaction_invoice {
  id?: number;
  order_reference: string = "";
  items: itemInput[] = [];
  phone: string = "";
  email: string = "";
  name: string = "";
  note: string = "";
  token: string = "";
}

export class resend_transaction_input {
  invoice: Add_transaction_invoice = new Add_transaction_invoice();
  business: businessInput = new businessInput();
}

export class businessInput {
  name: string = "";
  id?: number;
}
