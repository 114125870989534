import { gql } from '@apollo/client';

export class AddressClass{
    address1: string = '';
    address2: string = '';
    city: string = '';
    stateCd: string = '';
    zip: string = '';
    countryCd: string = '';

    static FRAGMENT = gql`
    fragment AddressClass_Fields on AddressClass {
        address1
        address2
        city
        stateCd
        zip
        countryCd
    }
  `; 
}

export class DemographicMinimal{
    businessAddress: AddressClass = new AddressClass();
    mailingAddress: AddressClass = new AddressClass();

    static FRAGMENT = gql`
    fragment DemographicMinimal_Fields on DemographicMinimal {
        businessAddress{...AddressClass_Fields}
        mailingAddress{...AddressClass_Fields}
    }
    ${AddressClass.FRAGMENT}
  `; 
}

export class BankInfo{
    bankAcctNum: string = '';
    bankRoutingNum: string = '';
    bankAcctTypeCd: string = '';
    bankName: string = '';

    static FRAGMENT = gql`
    fragment BankInfo_Fields on BankInfo {
        bankAcctNum
        bankRoutingNum
        bankAcctTypeCd
        bankName
    }
  `; 
}


export class BankDetail{
    depositBank: BankInfo = new BankInfo();
    withdrawalBank: BankInfo = new BankInfo();

    static FRAGMENT = gql`
    fragment BankDetail_Fields on BankDetail {
        depositBank{...BankInfo_Fields}
        withdrawalBank{...BankInfo_Fields}
    }
    ${BankInfo.FRAGMENT}
  `; 
}



export class OwnerSiteUser{
    firstName: string = '';
    lastName: string = '';
    email: string = '';

    static FRAGMENT = gql`
    fragment OwnerSiteUser_Fields on OwnerSiteUser {
        firstName
        lastName
        email
    }
  `; 
}


export class Hierarchy {
    corpLevel: string = '';
    chainLevel: string = '';
    sponsorBankCd: string = '';

    static FRAGMENT = gql`
    fragment Hierarchy_Fields on Hierarchy {
        corpLevel
        chainLevel
        sponsorBankCd
    }
  `; 
}

export class Demographic{
    businessIncorporatedStateCd: string = '';
    merchantTimeZone: string = '';
    websiteAddress: string = '';
    businessPhone: string = '';
    businessFax: string = '';
    businessAddress: AddressClass = new AddressClass();
    mailingAddress: AddressClass = new AddressClass();

    static FRAGMENT = gql`
    fragment Demographic_Fields on Demographic {
        businessIncorporatedStateCd
        merchantTimeZone
        websiteAddress
        businessPhone
        businessFax
        businessAddress{...AddressClass_Fields}
        mailingAddress{...AddressClass_Fields}
    }
    ${AddressClass.FRAGMENT}
  `; 
}



export class Owner{
    ownerAddress: AddressClass = new AddressClass();
    ownerEmail: string = '';
    ownerName: string = '';
    ownerDob: string = '';
    ownerPhone: string = '';
    ownerMobilePhone: string = '';
    ownerSSN: string = '';
    ownerTitle: string = '';

    static FRAGMENT = gql`
    fragment Owner_Fields on Owner {
        ownerAddress{...AddressClass_Fields}
        ownerEmail
        ownerName
        ownerDob
        ownerPhone
        ownerMobilePhone
        ownerSSN
        ownerTitle
    }
    ${AddressClass.FRAGMENT}
  `; 
}

export class AdditionalOwner{
    ownerFirstName: string = '';
    ownerLastName: string = '';
    ownershipPct: number = 0;

    static FRAGMENT = gql`
    fragment AdditionalOwner_Fields on AdditionalOwner {
        ownerFirstName
        ownerLastName
        ownershipPct
    }

  `; 
}

export class ContactInfo {
    contactName: string = '';
    contactEmail: string = '';
    contactPhone: string = '';

    static FRAGMENT = gql`
    fragment ContactInfo_Fields on ContactInfo {
        contactName
        contactEmail
        contactPhone
    }
  `; 
}



export class PlatformDetails{
    backEndMid: string = '';
    frontEndMid: string = '';
    processorReportingMid: string = '';
    backEndPlatformCd: string = '';
    frontEndPlatformCd: string = '';
    amexProgramAssetCd: string = '';
    amexProgramMid: string = '';
    discoverMid: string = '';
    discoverProgramCd: string = '';
    incrementalAuthorizationFlg: boolean = false;
    debtRepaymentProgramFlg: boolean = false;
    acquiringFlg: boolean = false;
    taxId: string = '';
    tid: string = '';
    busnsId: string = '';
    busnsIdPlaceOfIssue: string = '';
    mccId: string = '';
    currencyCode: string = '';
    businessDescription: string = '';
    iataCode: string = '';
    quasiCashFlg: boolean = false;

    static FRAGMENT = gql`
    fragment PlatformDetails_Fields on PlatformDetails {
        backEndMid
        frontEndMid
        processorReportingMid
        backEndPlatformCd
        frontEndPlatformCd
        amexProgramAssetCd
        amexProgramMid
        discoverMid
        discoverProgramCd
        incrementalAuthorizationFlg
        debtRepaymentProgramFlg
        acquiringFlg
        taxId
        tid
        busnsId
        busnsIdPlaceOfIssue
        mccId
        currencyCode
        businessDescription
        iataCode
        quasiCashFlg
    }
  `; 
}

export class BusinessDetails{
    customerBillPriorToShipFlg: boolean = false;
    depositReqForFulfillFlg: boolean = false;
    whenCustomerChargedCd: string = '';
    refundPolicyCd: string = '';
    serviceProvidedInCd: string = '';

    static FRAGMENT = gql`
    fragment BusinessDetails_Fields on BusinessDetails {
        customerBillPriorToShipFlg
        depositReqForFulfillFlg
        whenCustomerChargedCd
        refundPolicyCd
        serviceProvidedInCd
    }
  `; 
}

export class VolumeDetails{
    averageMonthlyVolume: number = 0;
    highTicketAmount: number = 0;
    averageTicketAmount: number = 0;

    static FRAGMENT = gql`
    fragment VolumeDetails_Fields on VolumeDetails {
        averageMonthlyVolume
        highTicketAmount
        averageTicketAmount
    }
  `; 
}

export class DeliveryPercentages{
    dlvry0To7DaysPct: number = 0;
    dlvry15To30DaysPct: number = 0;
    dlvry8To14DaysPct: number = 0;
    dlvryOver30DaysPct: number = 0;

    static FRAGMENT = gql`
    fragment DeliveryPercentages_Fields on DeliveryPercentages {
        dlvry0To7DaysPct
        dlvry15To30DaysPct
        dlvry8To14DaysPct
        dlvryOver30DaysPct
    }
  `; 
}

export class ModeOfTransaction{
    eCommercePct: number = 0;
    keyedPct: number = 0;
    mailOrderPct: number = 0;
    swipedPct: number = 0;

    static FRAGMENT = gql`
    fragment ModeOfTransaction_Fields on ModeOfTransaction {
        eCommercePct
        keyedPct
        mailOrderPct
        swipedPct
    }
  `; 
}

export class BlueChexVolume{
    averagePerTransactionSalesAmount: string = '';
    maxPerTransactionSalesAmount: string = '';
    averagePerTransactionCreditAmount: string = '';
    maxPerTransactionCreditAmount: string = '';
    averagePerMonthSalesAmount: string = '';
    maxPerMonthSalesAmount: string = '';
    averagePerMonthCreditAmount: string = '';
    maxPerMonthCreditAmount: string = '';

    static FRAGMENT = gql`
    fragment BlueChexVolume_Fields on BlueChexVolume {
        averagePerTransactionSalesAmount
        maxPerTransactionSalesAmount
        averagePerTransactionCreditAmount
        maxPerTransactionCreditAmount
        averagePerMonthSalesAmount
        maxPerMonthSalesAmount
        averagePerMonthCreditAmount
        maxPerMonthCreditAmount
    }
  `; 
}

export class BlueChexSecOptions{
    telSecOptionFlg: boolean = false;
    ccdSecOptionFlg: boolean = false;
    webSecOptionFlg: boolean = false;
    ppdSecOptionFlg: boolean = false;

    static FRAGMENT = gql`
    fragment BlueChexSecOptions_Fields on BlueChexSecOptions {
        telSecOptionFlg
        ccdSecOptionFlg
        webSecOptionFlg
        ppdSecOptionFlg
    }
  `; 
}





export class CardDiscount{
    qualCreditDiscountPct: number = 0;

    static FRAGMENT = gql`
    fragment CardDiscount_Fields on CardDiscount {
        qualCreditDiscountPct
    }
  `; 
}

export class AmexDiscount{
    esaQualDiscountPct: number = 0;
    optBlueQualDiscountPct: number = 0;

    static FRAGMENT = gql`
    fragment AmexDiscount_Fields on AmexDiscount {
        esaQualDiscountPct
        optBlueQualDiscountPct
    }
  `; 
}

export class IcPlusPricing{
    interchangeTypeCode: string = '';
    amex: AmexDiscount = new AmexDiscount();
    discover: CardDiscount = new CardDiscount();
    mastercard: CardDiscount = new CardDiscount();
    visa: CardDiscount = new CardDiscount();

    static FRAGMENT = gql`
    fragment IcPlusPricing_Fields on IcPlusPricing {
        interchangeTypeCode
        amex{...AmexDiscount_Fields}
        discover{...CardDiscount_Fields}
        mastercard{...CardDiscount_Fields}
        visa{...CardDiscount_Fields}
    }
        ${AmexDiscount.FRAGMENT}
        ${CardDiscount.FRAGMENT}
    `; 
}

export class Fees{
    achBatchFee: number = 0;
    addressVerifFee: number = 0;
    annualMembershipFee: number = 0;
    appFee: number = 0;
    authFee: number = 0;
    chargebackFee: number = 0;
    ddaRejectFee: number = 0;
    earlyCancelFee: number = 0;
    minProcessFee: number = 0;
    monthlyEquipmentRentalFee: number = 0;
    pciProgramCd: string = '';
    pciAnnualFee: number = 0;
    pciNonComplianceFee: number = 0;
    regProdMonthlyFee: number = 0;
    retrievalFee: number = 0;
    statementFee: number = 0;
    transactionFee: number = 0;
    voiceAuthFee: number = 0;
    wirelessActivationFee: number = 0;
    wirelessFee: number = 0;
    duesAndAssessmentsFlg: boolean = false;
    passthruInterchgCostsFlg: boolean = false;

    static FRAGMENT = gql`
    fragment Fees_Fields on Fees {
        achBatchFee
        addressVerifFee
        annualMembershipFee
        appFee
        authFee
        chargebackFee
        ddaRejectFee
        earlyCancelFee
        minProcessFee
        monthlyEquipmentRentalFee
        pciProgramCd
        pciAnnualFee
        pciNonComplianceFee
        regProdMonthlyFee
        retrievalFee
        statementFee
        transactionFee
        voiceAuthFee
        wirelessActivationFee
        wirelessFee
        duesAndAssessmentsFlg
        passthruInterchgCostsFlg
    }
  `; 
}

export class CloverSecurityAndTransarmor{
    transarmorDataProtectionFlg: boolean = false;
    transarmorTokenTypeCd: string = '';
    transarmorMultiPayToken: string = '';
    transarmorMonthyFee: number = 0;

    static FRAGMENT = gql`
    fragment CloverSecurityAndTransarmor_Fields on CloverSecurityAndTransarmor {
        transarmorDataProtectionFlg
        transarmorTokenTypeCd
        transarmorMultiPayToken
        transarmorMonthyFee
    }
  `; 
}

export class CustomField{
    userFieldNumber: number = 0;
    customFieldValue: string = '';

    static FRAGMENT = gql`
    fragment CustomField_Fields on CustomField {
        userFieldNumber
        customFieldValue
    }
  `; 
}

export class IdRes{
    id?: number;
    success: boolean = false;
    message: string = '';

    static FRAGMENT = gql`
    fragment IdRes_Fields on IdRes {
        id
        success
        message
    }`; 
}

export class Ownership{
    owner: Owner = new Owner();
    ownershipTypeCd: string = '';
    publiclyTradedFlag: string = '';
    stockSymbol: string = '';
    driversLicenseNumber: string = '';
    driversLicenseStateCd: string = '';
    ownerOwnershipPct: number = 0;
    additionalOwners: AdditionalOwner[] = []

    static FRAGMENT = gql`
    fragment Ownership_Fields on Ownership {
        owner{...Owner_Fields}
        ownershipTypeCd
        publiclyTradedFlag
        stockSymbol
        driversLicenseNumber
        driversLicenseStateCd
        ownerOwnershipPct
        additionalOwners{...AdditionalOwner_Fields}
    }
    ${Owner.FRAGMENT}
    ${AdditionalOwner.FRAGMENT}

  `; 
}


export class  MerchantApplicationMinimal_input {
    templateId?: number;
    merchant: MerchantMinimal_input = new MerchantMinimal_input();
    ownerSiteUser: OwnerSiteUser_input = new OwnerSiteUser_input();
}

export class  MerchantMinimal_input{
    salesCode: string = '';
    dbaName: string = '';
    legalBusinessName: string = '';
    taxFilingName: string = '';
    taxFilingMethod: string = '';
    demographic: DemographicMinimal_input = new DemographicMinimal_input();
    ownership: OwnershipMinimal_input = new OwnershipMinimal_input();
    bankDetail: BankDetail_input = new BankDetail_input();
}

export class  OwnershipMinimal_input{
    owner: OwnerMinimal_input = new OwnerMinimal_input();
    driversLicenseNumber: string = '';
    driversLicenseStateCd: string = '';
}

export class  OwnerMinimal_input{
    ownerAddress: AddressClass_input = new AddressClass_input();
    ownerEmail: string = '';
    ownerName: string = '';
    ownerPhone: string = '';
    ownerTitle: string = '';
}

export class  DemographicMinimal_input{
    businessAddress: AddressClass_input = new AddressClass_input();
    mailingAddress: AddressClass_input = new AddressClass_input();
}

export class  AddressClass_input{
    address1: string = '';
    address2: string = '';
    city: string = '';
    stateCd: string = '';
    zip: string = '';
    countryCd: string = '';
}

export class  BankDetail_input{
    depositBank: BankInfo_input = new BankInfo_input();
    withdrawalBank: BankInfo_input = new BankInfo_input();
}

export class  BankInfo_input{
    bankAcctNum: string = '';
    bankRoutingNum: string = '';
    bankAcctTypeCd: string = '';
    bankName: string = '';
}

export class  OwnerSiteUser_input{
    firstName: string = '';
    lastName: string = '';
    email: string = '';
}

export class  Merchant_input{
    salesCode: string = '';
    akaBusinessName: string = '';
    dbaName: string = '';
    legalBusinessName: string = '';
    taxFilingName: string = '';
    taxFilingMethod: string = '';
    businessStartDate: string = '';
    businessIdTypeCd: string = '';
    custPrimaryAcctFlg: boolean = false;
    webLeadFlg: boolean = false;
    hierarchy: Hierarchy_input = new Hierarchy_input();
    demographic: Demographic_input = new Demographic_input();
    ownership: Ownership_input =  new Ownership_input();
    bankDetail: BankDetail_input =  new BankDetail_input();
    merchantContactInfo: ContactInfo_input =  new ContactInfo_input();
    processing: Processing_input =  new Processing_input();
    pricing: Pricing_input =  new Pricing_input();
    fees: Fees_input =  new Fees_input();
    cloverSecurityAndTransarmor: CloverSecurityAndTransarmor_input =  new CloverSecurityAndTransarmor_input();
    customFields: CustomField_input[] = [];
}

export class  Hierarchy_input {
    corpLevel: string = '';
    chainLevel: string = '';
    sponsorBankCd: string = '';
}

export class  Demographic_input{
    businessIncorporatedStateCd: string = '';
    merchantTimeZone: string = '';
    websiteAddress: string = '';
    businessPhone: string = '';
    businessFax: string = '';
    businessAddress: AddressClass_input = new AddressClass_input();
    mailingAddress: AddressClass_input = new AddressClass_input();
}

export class  Ownership_input{
    owner: Owner_input = new Owner_input();
    ownershipTypeCd: string = '';
    publiclyTradedFlag: string = '';
    stockSymbol: string = '';
    driversLicenseNumber: string = '';
    driversLicenseStateCd: string = '';
    ownerOwnershipPct: number = 0;
    additionalOwners: AdditionalOwner_input[] = [];
}

export class  Owner_input{
    ownerAddress: AddressClass_input = new AddressClass_input();
    ownerEmail: string = '';
    ownerName: string = '';
    ownerDob: string = '';
    ownerPhone: string = '';
    ownerMobilePhone: string = '';
    ownerSSN: string = '';
    ownerTitle: string = '';
}

export class  AdditionalOwner_input{
    ownerFirstName: string = '';
    ownerLastName: string = '';
    ownershipPct: number = 0;
}

export class  ContactInfo_input {
    contactName: string = '';
    contactEmail: string = '';
    contactPhone: string = '';
}

export class  Processing_input{
    platformDetails: PlatformDetails_input = new PlatformDetails_input();
    businessDetails: BusinessDetails_input = new BusinessDetails_input();
    volumeDetails: VolumeDetails_input = new VolumeDetails_input();
    deliveryPercentages: DeliveryPercentages_input =  new DeliveryPercentages_input();
    modeOfTransaction: ModeOfTransaction_input =  new ModeOfTransaction_input();
    blueChexVolume: BlueChexVolume_input =  new BlueChexVolume_input();
    blueChexSecOptions: BlueChexSecOptions_input =  new BlueChexSecOptions_input();
}

export class  PlatformDetails_input{
    backEndMid: string = '';
    frontEndMid: string = '';
    processorReportingMid: string = '';
    backEndPlatformCd: string = '';
    frontEndPlatformCd: string = '';
    amexProgramAssetCd: string = '';
    amexProgramMid: string = '';
    discoverMid: string = '';
    discoverProgramCd: string = '';
    incrementalAuthorizationFlg: boolean = false;
    debtRepaymentProgramFlg: boolean = false;
    acquiringFlg: boolean = false;
    taxId: string = '';
    tid: string = '';
    busnsId: string = '';
    busnsIdPlaceOfIssue: string = '';
    mccId: string = '';
    currencyCode: string = '';
    businessDescription: string = '';
    iataCode: string = '';
    quasiCashFlg: boolean = false;
}

export class  BusinessDetails_input{
    customerBillPriorToShipFlg: boolean = false;
    depositReqForFulfillFlg: boolean = false;
    whenCustomerChargedCd: string = '';
    refundPolicyCd: string = '';
    serviceProvidedInCd: string = '';
}

export class  VolumeDetails_input{
    averageMonthlyVolume: number = 0;
    highTicketAmount: number = 0;
    averageTicketAmount: number = 0;
}

export class  DeliveryPercentages_input{
    dlvry0To7DaysPct: number = 0;
    dlvry15To30DaysPct: number = 0;
    dlvry8To14DaysPct: number = 0;
    dlvryOver30DaysPct: number = 0;
}

export class  ModeOfTransaction_input{
    eCommercePct: number = 0;
    keyedPct: number = 0;
    mailOrderPct: number = 0;
    swipedPct: number = 0;
}

export class  BlueChexVolume_input{
    averagePerTransactionSalesAmount: string = '';
    maxPerTransactionSalesAmount: string = '';
    averagePerTransactionCreditAmount: string = '';
    maxPerTransactionCreditAmount: string = '';
    averagePerMonthSalesAmount: string = '';
    maxPerMonthSalesAmount: string = '';
    averagePerMonthCreditAmount: string = '';
    maxPerMonthCreditAmount: string = '';
}

export class  BlueChexSecOptions_input{
    telSecOptionFlg: boolean = false;
    ccdSecOptionFlg: boolean = false;
    webSecOptionFlg: boolean = false;
    ppdSecOptionFlg: boolean = false;
}

export class  Pricing_input{
    flatPricing: FlatPricing_input = new FlatPricing_input();
    icPlusPricing: IcPlusPricing_input = new IcPlusPricing_input();
}

export class FlatPricing_input{
    amex: AmexDiscount_input = new AmexDiscount_input();
    discover: CardDiscount_input = new CardDiscount_input();
    mastercard: CardDiscount_input = new CardDiscount_input();
    visa: CardDiscount_input = new CardDiscount_input();
}

export class  CardDiscount_input{
    qualCreditDiscountPct: number = 0;
}

export class  AmexDiscount_input{
    esaQualDiscountPct: number = 0;
    optBlueQualDiscountPct: number = 0;
}

export class  IcPlusPricing_input{
    interchangeTypeCode: string = '';
    amex: AmexDiscount_input = new AmexDiscount_input();
    discover: CardDiscount_input = new CardDiscount_input();
    mastercard: CardDiscount_input = new CardDiscount_input();
    visa: CardDiscount_input = new CardDiscount_input();
}

export class  Fees_input{
    achBatchFee: number = 0;
    addressVerifFee: number = 0;
    annualMembershipFee: number = 0;
    appFee: number = 0;
    authFee: number = 0;
    chargebackFee: number = 0;
    ddaRejectFee: number = 0;
    earlyCancelFee: number = 0;
    minProcessFee: number = 0;
    monthlyEquipmentRentalFee: number = 0;
    pciProgramCd: string = '';
    pciAnnualFee: number = 0;
    pciNonComplianceFee: number = 0;
    regProdMonthlyFee: number = 0;
    retrievalFee: number = 0;
    statementFee: number = 0;
    transactionFee: number = 0;
    voiceAuthFee: number = 0;
    wirelessActivationFee: number = 0;
    wirelessFee: number = 0;
    duesAndAssessmentsFlg: boolean = false;
    passthruInterchgCostsFlg: boolean = false;
}

export class  CloverSecurityAndTransarmor_input{
    transarmorDataProtectionFlg: boolean = false;
    transarmorTokenTypeCd: string = '';
    transarmorMultiPayToken: string = '';
    transarmorMonthyFee: number = 0;
}

export class  CustomField_input{
    userFieldNumber: number = 0;
    customFieldValue: string = '';
}

export class Processing{
    platformDetails: PlatformDetails = new PlatformDetails();
    businessDetails: BusinessDetails = new BusinessDetails();
    volumeDetails: VolumeDetails = new VolumeDetails();
    deliveryPercentages: DeliveryPercentages = new DeliveryPercentages();
    modeOfTransaction: ModeOfTransaction = new ModeOfTransaction();
    blueChexVolume: BlueChexVolume = new BlueChexVolume();
    blueChexSecOptions: BlueChexSecOptions = new BlueChexSecOptions();

    static FRAGMENT = gql`
    fragment Processing_Fields on Processing {
        platformDetails{...PlatformDetails_Fields}
        businessDetails{...BusinessDetails_Fields}
        volumeDetails{...VolumeDetails_Fields}
        deliveryPercentages{...DeliveryPercentages_Fields}
        modeOfTransaction{...ModeOfTransaction_Fields}
        blueChexVolume{...BlueChexVolume_Fields}
        blueChexSecOptions{...BlueChexSecOptions_Fields}
    }
    ${PlatformDetails.FRAGMENT}
    ${BusinessDetails.FRAGMENT}
    ${VolumeDetails.FRAGMENT}
    ${DeliveryPercentages.FRAGMENT}
    ${ModeOfTransaction.FRAGMENT}
    ${BlueChexVolume.FRAGMENT}
    ${BlueChexSecOptions.FRAGMENT}
  `; 
}

export class FlatPricing{
    amex: AmexDiscount = new AmexDiscount();
    discover: CardDiscount = new CardDiscount();
    mastercard: CardDiscount = new CardDiscount();
    visa: CardDiscount = new CardDiscount();

    static FRAGMENT = gql`
    fragment FlatPricing_Fields on FlatPricing {
        amex{...AmexDiscount_Fields}
        discover{...CardDiscount_Fields}
        mastercard{...CardDiscount_Fields}
        visa{...CardDiscount_Fields}
    }
    ${AmexDiscount.FRAGMENT}
    ${CardDiscount.FRAGMENT}
  `; 
}

export class Pricing{
    flatPricing: FlatPricing = new FlatPricing();
    icPlusPricing: IcPlusPricing = new IcPlusPricing();

    static FRAGMENT = gql`
    fragment Pricing_Fields on Pricing {
        flatPricing{...FlatPricing_Fields}
        icPlusPricing{...IcPlusPricing_Fields}
    }
    ${FlatPricing.FRAGMENT}
    ${IcPlusPricing.FRAGMENT}
  `; 

}



export class Merchant{
    merchantId?: number;
    customerId?: number;  
    salesCode: string = '';
    akaBusinessName: string = '';
    dbaName: string = '';
    legalBusinessName: string = '';
    taxFilingName: string = '';
    taxFilingMethod: string = '';
    businessStartDate: string = '';
    businessIdTypeCd: string = '';
    custPrimaryAcctFlg: boolean = false;
    webLeadFlg: boolean = false;
    hierarchy: Hierarchy = new Hierarchy();
    demographic: Demographic = new Demographic();
    ownership: Ownership =  new Ownership();
    bankDetail: BankDetail = new BankDetail();
    merchantContactInfo: ContactInfo = new ContactInfo();
    processing: Processing = new Processing();
    pricing: Pricing = new Pricing();
    fees: Fees = new Fees();
    cloverSecurityAndTransarmor: CloverSecurityAndTransarmor = new CloverSecurityAndTransarmor();
    customFields: CustomField[] = [];

    static FRAGMENT = gql`
    fragment Merchant_Fields on Merchant {
        merchantId
        customerId
        salesCode
        akaBusinessName
        dbaName
        legalBusinessName
        taxFilingName
        taxFilingMethod
        businessStartDate
        businessIdTypeCd
        custPrimaryAcctFlg
        webLeadFlg
        hierarchy{...Hierarchy_Fields}
        demographic{...Demographic_Fields}
        ownership{...Ownership_Fields}
        bankDetail{...BankDetail_Fields}
        merchantContactInfo{...ContactInfo_Fields}
        processing{...Processing_Fields}
        pricing{...Pricing_Fields}
        fees{...Fees_Fields}
        cloverSecurityAndTransarmor{...CloverSecurityAndTransarmor_Fields}
        customFields{...CustomField_Fields}
    }
    ${Hierarchy.FRAGMENT}
    ${Demographic.FRAGMENT}
    ${Ownership.FRAGMENT}
    ${BankDetail.FRAGMENT}
    ${ContactInfo.FRAGMENT}
    ${Processing.FRAGMENT}
    ${Pricing.FRAGMENT}
    ${Fees.FRAGMENT}
    ${CloverSecurityAndTransarmor.FRAGMENT}
    ${CustomField.FRAGMENT}
  `; 
}




