import { gql } from '@apollo/client';

import {Business} from "../business/index";

export class Charge_preset{
    id?: number;
    business: Business = new Business();
    name: string = '';
    base_amount: number = 0;
    tax_amount: number = 0;

    static FRAGMENT = gql`
    fragment Charge_preset_Fields on Charge_preset {
        id
        business{...Business_Fields}
        name
        base_amount
        tax_amount
    }
    ${Business.FRAGMENT}
  `; 
}