import React from "react";
import "./newcharge.scss";
import "../../../assets/scss/flexbox.scss";
import {
  BigTotal,
  Presets,
  SearchField,
} from "@profuse-studio/profuse-pay-uix";

export const NewCharge = () => {
  return (
    <div className="new-charges">
      <div className="new-charges-container row mt-5">
        <div className="col-12 h-25 ">
          <BigTotal name="Sunshine Dry Cleaning Co." total={21.9} />
        </div>
        <div className="col-12 row  mt-4">
          <div className="col-6 col-sm-12 my-1 center ">
            <p>Click on the buttons on the right side to start </p>
          </div>
          <div className="col-6 col-sm-12 ">
            <div className="col-11-5 ">
              <SearchField
                background="#EEEEEE;"
                onChange={function noRefCheck() {}}
              />
              <div>
                <Presets
                  hoverType="quantity"
                  onDeleted={function noRefCheck() {}}
                  onEdited={function noRefCheck() {}}
                  onQuantityChanged={function noRefCheck() {}}
                  presetsName="Shirt"
                  price={10}
                  quantity={2}
                  tax={9.5}
                  taxName="Sales tax (9.5%)"
                />
              </div>

              <Presets
                hoverType="quantity"
                onDeleted={function noRefCheck() {}}
                onEdited={function noRefCheck() {}}
                onQuantityChanged={function noRefCheck() {}}
                presetsName="Shirt"
                price={10}
                quantity={2}
                tax={9.5}
                taxName="Sales tax (9.5%)"
              />
              <Presets
                hoverType="quantity"
                onDeleted={function noRefCheck() {}}
                onEdited={function noRefCheck() {}}
                onQuantityChanged={function noRefCheck() {}}
                presetsName="Shirt"
                price={10}
                quantity={2}
                tax={9.5}
                taxName="Sales tax (9.5%)"
              />
            </div>
          </div>
        </div>
        {/* <p className="col-4" style={{backgroundColor:'blue'}}>Left Column</p>
            <p className="col-4" style={{backgroundColor:'blue'}}>Center Column</p>
            <p className="col-4" style={{backgroundColor:'blue'}}>Right Column</p> */}
      </div>
    </div>
  );
};
