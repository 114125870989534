import { gql } from "@apollo/client";

export class Cardconnect {
  id?: number;
  mid: string = "";
  note: string = "";

  static FRAGMENT = gql`
    fragment Cardconnect_Fields on Cardconnect {
      id
      mid
      note
    }
  `;
}
