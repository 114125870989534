import React, { useEffect, useRef, useState } from "react";
import { Route, Routes } from "react-router-dom";
// import Footer from "../../components/Footer/Footer";
import { Navbar } from "../../components/Navbar/Navbar";
import { Invoices } from "../Invoices/Invoices";
import { ChargeHistory } from "../ChargeHistory/ChargeHistory";
import { DailyReports } from "../DailyReports/DailyReports";
import { NewCharge } from "../NewCharge/NewCharge";
import { Settings } from "../Settings/Settings";
import Helper from "../../utilities/Helper";
import "./home.scss";
import { TopBar } from "@profuse-studio/profuse-pay-uix";
import mainLogo from "../../../assets/images/ProfusePay_logo_40px.png";
import LogOuticon from "../../../assets/images/LogOuticon.svg";
// import webpack from 'webpack';
const NavButtons: { title: string; link: string }[] = [
  {
    title: "Invoices",
    link: "/invoices",
  },
  {
    title: "Charge history",
    link: "/chargehistory",
  },
  {
    title: "Daily reports",
    link: "/dailyreports",
  },
  {
    title: "Settings",
    link: "/settings",
  },
];

export const Home = () => {
  const [height, setHeight] = useState(0);
  const [avatarMenuOpened, setAvatarMenuOpened] = useState<boolean>(false);
  const [route, setRoute] = useState<string>(
    Helper.Navigation.GetCurrentLocation()
  );

  const GetInitials = () => {
    let names = Helper.Session.User.name.split(" ");
    let res = names
      .filter((x) => x)
      .map((x) => x[0].toUpperCase())
      .join("");
    return res;
  };

  const LogOutRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (LogOutRef.current && !LogOutRef.current.contains(e.target)) {
        setAvatarMenuOpened(!avatarMenuOpened);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [avatarMenuOpened]);

  const [avatar, setAvatar] = useState<string>(GetInitials);

  function onResize() {
    setHeight(window.innerHeight);
  }

  useEffect(() => {
    onResize();
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  useEffect(() => {
    console.log('First Home load')
    CheckToken();
  }, []);

  // useEffect(() => {
  //   setAvatar(GetInitials);
  // }, [route]);

  async function CheckToken() {
    try {
      await Helper.Session.VerifyUser();
      setAvatar(GetInitials);
    } catch (ex: any) {
      console.log("Error in CheckToken: ", ex.message);
      Helper.Session.DoLogout();
    }
  }
  function handleUserLogout() {
    Helper.Session.DoLogout(false);
  }

  function changeRoute(newroute: string) {
    setRoute(newroute);
    Helper.Navigation.NavigateTo(newroute);
    //window.location.reload();
  }

  const NotFound = (props: any) => {
    useEffect(() => {
      if (route === "/" || route === "") {
        Helper.Navigation.NavigateTo("/invoices");
      } else {
        Helper.Navigation.NavigateTo("/404");
      }
    }, [props.text]);
    return <h1>404</h1>;
  };

  return (
    <div className="main" style={{ height: height + "px" }}>
      <div className="canvas">
        <div style={{position: "relative"}}>
          <TopBar
            buttons={NavButtons}
            avatar={avatar}
            logo={mainLogo}
            onAvatarClicked={() => setAvatarMenuOpened(true)}
            onLogoClicked={() => {
              window.location.href = "https://app.profusepay.com/";
            }}
            currentRoute={route}
            onLinkClick={(link) => {
              changeRoute(link);
            }}
            onNewChargeClick={() => {
              changeRoute("/NewCharge");
            }}
          />
        {avatarMenuOpened && (
          <div
            className="navbar-user-logout"
            onClick={handleUserLogout}
            ref={LogOutRef}
          >
            <img src={LogOuticon} className="log-out-icon" alt="log-out-icon" />
            <p className="log-oute-text">Log Out</p>
          </div>
        )}
        </div>
        <div className="route-body">
          <Routes>
            <Route path="/invoices/*" element={<Invoices />} />
            <Route path="/chargehistory/*" element={<ChargeHistory />} />
            <Route path="/dailyreports/*" element={<DailyReports />} />
            <Route path="/settings/*" element={<Settings />} />
            <Route path="/NewCharge/*" element={<NewCharge />}></Route>
            {/* <Route path="/" element={<NotFound/>} /> */}
            <Route path="/*" element={<NotFound />} />
          </Routes>
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  );
};
