import { gql } from "@apollo/client";

export class Cardconnect_terminal {
  id?: number;
  hsn: string = "";

  static FRAGMENT = gql`
    fragment Cardconnect_terminal_Fields on Cardconnect_terminal {
      id
      hsn
    }
  `;
}

export class tipData {
  amount: number = 0;
  tip: number = 0;
  total: number = 0;

  static FRAGMENT = gql`
    fragment tipData_Fields on tipData {
      amount
      tip
      total
    }
  `;
}

export class tipResponse {
  success: boolean = false;
  data: tipData = new tipData();
  error: string = "";
  identifier: string = "";

  static FRAGMENT = gql`
    fragment tipResponse_Fields on tipResponse {
      success
      data {
        ...tipData_Fields
      }
      error
      identifier
    }
    ${tipData.FRAGMENT}
  `;
}

export class terminalSP {
  id?: number;
  name: string = "";
  hsn: string = "";

  static FRAGMENT = gql`
    fragment terminalSP_Fields on terminalSP {
      id
      name
      hsn
    }
  `;
}

export class itemnameSP {
  name: string = "";
  base_amount: number = 0;
  tax_amount: number = 0;
  count: number = 0;
  amount: number = 0;

  static FRAGMENT = gql`
    fragment itemnameSP_Fields on itemnameSP {
      name
      base_amount
      tax_amount
      count
      amount
    }
  `;
}

export class Bininfo {
  country: string = "";
  product: string = "";
  bin: string = "";
  cardusestring: string = "";
  gsa: boolean = false;
  corporate: boolean = false;
  fsa: boolean = false;
  subtype: string = "";
  purchase: boolean = false;
  prepaid: boolean = false;
  binlo: string = "";
  issuer: string = "";
  binhi: string = "";
  success: boolean = false;
  errormsg: string = "";
  cardType: string = "";

  static FRAGMENT = gql`
    fragment Bininfo_Fields on Bininfo {
      country
      product
      bin
      cardusestring
      gsa
      corporate
      fsa
      subtype
      purchase
      prepaid
      binlo
      issuer
      binhi
      success
      errormsg
      cardType
    }
  `;
}

export class dtres {
  transaction_id?: number;
  token: string = "";
  expiry: string = "";
  name: string = "";
  batchid: string = "";
  retref: string = "";
  avsresp: string = "";
  respproc: string = "";
  amount: string = "";
  resptext: string = "";
  authcode: string = "";
  respcode: string = "";
  merchid: string = "";
  cvvresp: string = "";
  respstat: string = "";
  emvTagData: string = "";
  tokenExpired: boolean = false;
  orderid: string = "";
  entrymode: string = "";
  bintype: string = "";
  binInfo: Bininfo = new Bininfo();
  error: string = "";
  code: number = 0;
  session: string = "";

  static FRAGMENT = gql`
    fragment dtres_Fields on dtres {
      transaction_id
      token
      expiry
      name
      batchid
      retref
      avsresp
      respproc
      amount
      resptext
      authcode
      respcode
      merchid
      cvvresp
      respstat
      emvTagData
      tokenExpired
      orderid
      entrymode
      bintype
      binInfo {
        ...Bininfo_Fields
      }
      error
      code
      session
    }
    ${Bininfo.FRAGMENT}
  `;
}

export class terminalResponse {
  success: boolean = false;
  data: dtres = new dtres();
  identifier: string = "";
  tip_amount: number = 0;

  static FRAGMENT = gql`
    fragment terminalResponse_Fields on terminalResponse {
      success
      data {
        ...dtres_Fields
      }
      identifier
      tip_amount
    }
    ${dtres.FRAGMENT}
  `;
}

export class process_charge_res {
  success: boolean = false;
  data: dtres = new dtres();
  error: string = "";
  message: string = "";

  static FRAGMENT = gql`
    fragment process_charge_res_Fields on process_charge_res {
      success
      data {
        ...dtres_Fields
      }
      error
      message
    }
    ${dtres.FRAGMENT}
  `;
}

export class Addprocess_charge {
  order_reference: string = "";
  hsn: string = "";
  items: itemInput[] = [];
}

export class InputTerminalResponse {
  success: boolean = false;
  data: Inputdtres = new Inputdtres();
  identifier: string = "";
}

export class Inputdtres {
  transaction_id?: number;
  token: string = "";
  expiry: string = "";
  name: string = "";
  batchid: string = "";
  retref: string = "";
  avsresp: string = "";
  respproc: string = "";
  amount: string = "";
  resptext: string = "";
  authcode: string = "";
  respcode: string = "";
  merchid: string = "";
  cvvresp: string = "";
  respstat: string = "";
  emvTagData: string = "";
  tokenExpired: boolean = false;
  orderid: string = "";
  entrymode: string = "";
  bintype: string = "";
  binInfo: InputBininfo = new InputBininfo();
  error: string = "";
  code: number = 0;
  session: string = "";
}

export class InputBininfo {
  product: string = "";
  subtype: string = "";
}

export class itemInput {
  id?: number;
  name: string = "";
  base_amount: number = 0;
  tax_amount: number = 0;
  count: number = 0;
  amount: number = 0;
  reference: string = "";
}
