import React, { useState } from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { Login } from "./pages/Login/Login";
import { Home } from "./pages/Home/Home";
import Helper from "./utilities/Helper";
import { ForgotPassword } from "./pages/ForgotPassword/ForgotPassword";
import { PasswordReset } from "./pages/PasswordReset/PasswordReset";

import "./App.scss";
import { GridLoader } from "./components/Spinner/Grid";

function App() {
  let navigate = useNavigate();
  let location = useLocation();
  let load = useState<boolean>(false);
  Helper.SetNavigator(navigate, location);
  Helper.SetLoader(load);

  return (
    <>
      {load[0] && <GridLoader remSize={3}/>}
      <Routes>
        <Route path="/404" element={<h1>404 NOT FOUND</h1>} />
        <Route path="/login" element={<Login />} />
        <Route path="/login/*" element={<Login />} />
        <Route path="/forgotpassword" element={<ForgotPassword />}></Route>
        <Route path="/passwordreset/*" element={<PasswordReset />}></Route>
        <Route path="/*" element={<Home />} />
      </Routes>
    </>
  );
}

export default App;
