import React from 'react'
import "./dailyReports.scss";


export const  DailyReports = () => {
  return (
    <div>DailyReports</div>
  )
}



 