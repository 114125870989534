import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Helper from "../../utilities/Helper";
import { Queries } from "../../services/GraphQL/queries/index";
import { encrypt } from "../../services/ps-crypt";
import {
  Button,
  InputField,
  CheckBoxField,
} from "@profuse-studio/profuse-pay-uix";
import loginLogo from "../../../assets/images/ProfusePay_FullLogo.svg";
import hidePasswordImg from "../../../assets/images/Icon_eye_closed.png";
import showPasswordImg from "../../../assets/images/Icon_eye_open.png";

import "./login.scss";
export const Login = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errorText, setErrorText] = useState<string>("");

  let path = window.location.href.split('/login/')[1];
  //console.log(path?.replaceAll('$','/'));
  const [stayLoggedIn, setStayLoggedIn] = useState<boolean>(true);

  // const {path} = useParams<{path: string}>();
  // console.log(path?.replaceAll('$','/'));

  async function handleFormSubmit() {
    if(!validInputs()){
      return 
    }
    Helper.Session.SetLoading(true);
    try {
      setErrorText("");
      let data = encrypt({ email, password });
      let res = await Queries.authenticate_user({
        data,
        stay_signed: stayLoggedIn,
      });

      if (!res || !res.success) {
        throw new Error(res ? res.token : "No connection to the server");
      }

      Helper.Session.NewLogin(res, path);
    } catch (ex: any) {
      console.log("Error in handleFormSubmit: ", ex.message);
      setErrorText(ex.message);
    }
    Helper.Session.SetLoading(false);
  }

  function validInputs() {
    return password.length > 0 && Helper.Validators.IsValidEmail(email);
  }

  useEffect(() => {
    if (errorText) {
      setErrorText("");
    }
  }, [password, email]);

  return (
    <div className="login-component">
      <div className="login-container">
        <div className="login-logo">
          <img src={loginLogo} className="login-img" alt="logo" />
        </div>

        <div className="row">
          <p className="login-title mb-2">Log in</p>
          <div className="col-12 mb-1-5">
            <InputField
              type="email"
              name="email"
              placeholder="Email"
              required
              value={email}
              onChange={(e) => setEmail(e)}
              label={"Email"}
              onEnter={() => handleFormSubmit()}
              isValidated={(text) => {
                return !Helper.Validators.IsValidEmail(text)
                  ? "Invalid email"
                  : null;
              }}
            />
          </div>
          <div className="col-12" style={{ position: "relative" }}>
            <div className="password-reset"></div>
            <InputField
              type={showPassword ? "text" : "password"}
              name="password"
              placeholder="Password"
              required
              onChange={(e) => setPassword(e)}
              label={"Password"}
              onEnter={() => handleFormSubmit()}

              // isValidated={(text) => {return !Helper.Validators.IsValidPassword(text) ? 'Invalid Password' : null}}
            />
            <Link to="/forgotpassword" className="login-forgot-password">
              Forgot password?
            </Link>
            <img
              src={showPassword ? showPasswordImg : hidePasswordImg}
              className="login-password-input-toggle"
              alt="Login"
              onClick={(e) => setShowPassword(!showPassword)}
            />
          </div>
          <CheckBoxField
            name={`string`}
            type={`checkbox`}
            label="Stay signed in"
            checked={true}
            onChange={() => setStayLoggedIn(!stayLoggedIn)}
          />
          <p className="error-text mt-1 col-12">{errorText}</p>
          <div className="col-12 login-btn mt-2">
            <Button
              onClick={() => handleFormSubmit()}
              className={`primary`}
              label={`Login`}
              type={"fill"}
              active={validInputs()}
              btnColor={"primary"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
