import { gql } from "@apollo/client";
import { GraphqlService } from "../../graphql.service";
import { miRes } from "../cardconnect_transaction";
import { AuthRes, TokenClass } from "../user";

export const CHANGE_PASSWORD = gql`
  ${miRes.FRAGMENT}
  mutation ($username: String, $old_password: String, $new_password: String) {
    change_password(
      username: $username
      old_password: $old_password
      new_password: $new_password
    ) {
      ...miRes_Fields
    }
  }
`;

export function change_password(input: {
  username: String;
  old_password: String;
  new_password: String;
}): Promise<miRes> {
  return new Promise<miRes>(async (resolve, reject) => {
    try {
      let res = await GraphqlService.SendMutation(CHANGE_PASSWORD, input);
      resolve(res);
    } catch (ex: any) {
      console.log("Error in change_password: ", ex.message);
      reject({ message: ex.message });
    }
  });
}

// export const UPDATE_BUSINESS_SETTINGS = gql`
//   ${miRes.FRAGMENT}
//   mutation ($receipt_text: String, $invoice_text: String) {
//     update_business_settings(
//       receipt_text: $receipt_text
//       invoice_text: $invoice_text
//     ) {
//       ...miRes_Fields
//     }
//   }
// `;

// export function update_business_settings(input: {
//   receipt_text: String;
//   invoice_text: String;
// }): Promise<miRes> {
//   return new Promise<miRes>(async (resolve, reject) => {
//     try {
//       let res = await GraphqlService.SendMutation(
//         UPDATE_BUSINESS_SETTINGS,
//         input
//       );
//       resolve(res);
//     } catch (ex: any) {
//       console.log("Error in update_business_settings: ", ex.message);
//       reject({ message: ex.message });
//     }
//   });
// }

// export const UPDATE_BUSINESS_PROFILE = gql`
//   ${miRes.FRAGMENT}
//   mutation ($email: String, $contact_name: String, $phone: String) {
//     update_business_profile(
//       email: $email
//       contact_name: $contact_name
//       phone: $phone
//     ) {
//       ...miRes_Fields
//     }
//   }
// `;

// export function update_business_profile(input: {
//   email: String;
//   contact_name: String;
//   phone: String;
// }): Promise<miRes> {
//   return new Promise<miRes>(async (resolve, reject) => {
//     try {
//       let res = GraphqlService.SendMutation(UPDATE_BUSINESS_PROFILE, input);
//       resolve(res);
//     } catch (ex: any) {
//       console.log("Error in update_business_profile: ", ex.message);
//       reject({ message: ex.message });
//     }
//   });
// }

// // Need Ataintion from top-level
// export const UPDATE_BUSINESS = gql`
//   ${miRes.FRAGMENT}
//   mutation ($name: String) {
//     update_business(name: $name) {
//       ...miRes_Fields
//     }
//   }
// `;

// export function update_business(input: { name: String }): Promise<miRes> {
//   return new Promise<miRes>(async (resolve, reject) => {
//     try {
//       let res = GraphqlService.SendMutation(UPDATE_BUSINESS, input);
//       resolve(res);
//     } catch (ex: any) {
//       console.log("Error in update_business: ", ex.message);
//       reject({ message: ex.message });
//     }
//   });
// }

// request_password_reset(email: String): miRes

export const REQUEST_PASSWORD_RESET = gql`
  ${miRes.FRAGMENT}
  mutation ($email: String) {
    request_password_reset(email: $email) {
      ...miRes_Fields
    }
  }
`;

export function request_password_reset(input: {
  email: String;
}): Promise<miRes> {
  return new Promise<miRes>(async (resolve, reject) => {
    try {
      let res = GraphqlService.SendMutation(REQUEST_PASSWORD_RESET, input);
      resolve(res);
    } catch (ex: any) {
      console.log("Error in request_password_reset:", ex.message);
      reject({ message: ex.message });
    }
  });
}





export const CHANGE_FORGOT_PASSWORD = gql`
  ${AuthRes.FRAGMENT}
  mutation ($token: String, $data: String) {
    change_forgot_password(
      token: $token
      data: $data
    ) {
      ...AuthRes_Fields
    }
  }
`;

export function change_forgot_password(input: {
  token: String;
  data: String;
}): Promise<AuthRes> {
  return new Promise<AuthRes>(async (resolve, reject) => {
    try {
      let res = await GraphqlService.SendMutation(CHANGE_FORGOT_PASSWORD, input);
      resolve(res);
    } catch (ex: any) {
      console.log("Error in change_forgot_password: ", ex.message);
      reject({ message: ex.message });
    }
  });
}