import { gql } from "@apollo/client";
import { GraphqlService } from "../../graphql.service";
import { terminalSP, itemnameSP, terminalResponse, tipResponse } from "./index";

export const GET_TERMINALS = gql`
  ${terminalSP.FRAGMENT}
  query {
    get_terminals {
      ...terminalSP_Fields
    }
  }
`;

export function get_terminals(): Promise<terminalSP[]> {
  return new Promise<terminalSP[]>(async (resolve, reject) => {
    try {
      let res = await GraphqlService.SendQuery(GET_TERMINALS);
      resolve(res);
    } catch (ex: any) {
      console.log("Error in get_terminals: ", ex.message);
      reject({ message: ex.message });
    }
  });
}

export const GET_ITEM_NAMES = gql`
  ${itemnameSP.FRAGMENT}
  query {
    get_item_names {
      ...itemnameSP_Fields
    }
  }
`;

export function get_item_names(): Promise<itemnameSP[]> {
  return new Promise<itemnameSP[]>(async (resolve, reject) => {
    try {
      let res = await GraphqlService.SendQuery(GET_ITEM_NAMES);
      resolve(res);
    } catch (ex: any) {
      console.log("Error in get_item_names: ", ex.message);
      reject({ message: ex.message });
    }
  });
}

export const GET_TERMINAL_RESPONSE = gql`
  ${terminalResponse.FRAGMENT}
  query {
    get_terminal_response {
      ...tterminalResponse_Fields
    }
  }
`;

export function get_terminal_response(): Promise<terminalResponse[]> {
  return new Promise<terminalResponse[]>(async (resolve, reject) => {
    try {
      let res = await GraphqlService.SendQuery(GET_TERMINAL_RESPONSE);
      resolve(res);
    } catch (ex: any) {
      console.log("Error in get_terminal_response: ", ex.message);
      reject({ message: ex.message });
    }
  });
}

export const GET_TIP_RESPONSE = gql`
  ${tipResponse.FRAGMENT}
  query {
    get_tip_response {
      ...tipResponse_Fields
    }
  }
`;

export function get_tip_response(): Promise<tipResponse[]> {
  return new Promise<tipResponse[]>(async (resolve, reject) => {
    try {
      let res = await GraphqlService.SendQuery(GET_TIP_RESPONSE);
      resolve(res);
    } catch (ex: any) {
      console.log("Error in get_tip_response: ", ex.message);
      reject({ message: ex.message });
    }
  });
}
