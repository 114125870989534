import { gql } from "@apollo/client";
import { GraphqlService } from "../../graphql.service";
import {
  Addprocess_charge,
  InputTerminalResponse,
  process_charge_res,
} from "./index";

export const PROCESS_CHARGE_NOTIMEOUT = gql`
  mutation ($input: Addprocess_charge!) {
    process_charge_notimeout(input: $input)
  }
`;

export function process_charge_notimeout(input: {
  input: Addprocess_charge;
}): Promise<string> {
  return new Promise<string>(async (resolve, reject) => {
    try {
      let res = GraphqlService.SendMutation(PROCESS_CHARGE_NOTIMEOUT, input);
      resolve(res);
    } catch (ex: any) {
      console.log("Error in process_charge_notimeout: ", ex.message);
      reject({ message: ex.message });
    }
  });
}

export const CANCEL_TRANSACTION = gql`
${process_charge_res.FRAGMENT}
mutation($hsn: String){
    cancel_transaction(hsn : $hsn){
        ...process_charge_res_Fields
    }
}
`;

export function cancel_transaction(input: {
  hsn: String;
}): Promise<process_charge_res> {
  return new Promise<process_charge_res>(async (resolve, reject) => {
    try {
      let res = await GraphqlService.SendMutation(CANCEL_TRANSACTION, input);
      resolve(res);
    } catch (ex: any) {
      console.log("Error in cancel_transaction: ", ex.message);
      reject({ message: ex.message });
    }
  });
}

export const ADD_TERMINAL_RESPONSE = gql`
  mutation ($input: InputTerminalResponse) {
    add_terminal_response(input: $input)
  }
`;

export function add_terminal_response(input: {
  $input: InputTerminalResponse;
}): Promise<boolean> {
  return new Promise<boolean>(async (resolve, reject) => {
    try {
      let res = GraphqlService.SendMutation(ADD_TERMINAL_RESPONSE, input);
      resolve(res);
    } catch (ex: any) {
      console.log("Error in add_terminal_response: ", ex.message);
      reject({ message: ex.message });
    }
  });
}


export const HANDLED_TERMINAL_RESPONSE = gql`
mutation($identifier: String){
    handled_terminal_response(identifier : $identifier)
}
`;

export function handled_terminal_response(input: {
  identifier: String;
}): Promise<boolean> {
  return new Promise<boolean>(async (resolve, reject) => {
    try {
      let res = GraphqlService.SendMutation(HANDLED_TERMINAL_RESPONSE, input);
      resolve(res);
    } catch (ex: any) {
      console.log("Error in handled_terminal_response: ", ex.message);
      reject({ message: ex.message });
    }
  });
}

export const REQUEST_TIP = gql`
mutation($hsn: String, $total: Float){
    request_tip(hsn: $hsn, total: $total){
        ...request_tip
    }
}
`;

export function request_tip(input: {
  hsn: String;
  total: number;
}): Promise<string> {
  return new Promise<string>(async (resolve, reject) => {
    try {
      let res = await GraphqlService.SendMutation(REQUEST_TIP, input);
      resolve(res);
    } catch (ex: any) {
      console.log("Error in request_tip: ", ex.message);
      reject({ message: ex.message });
    }
  });
}

export const HANDLED_TERMINAL_TIP_RESPONSE = gql`
mutation($identifier: String){
    handled_terminal_tip_response(identifier : $identifier)
}
`;

export function handled_terminal_tip_response(input: {
  identifier: String;
}): Promise<boolean> {
  return new Promise<boolean>(async (resolve, reject) => {
    try {
      let res = await GraphqlService.SendMutation(
        HANDLED_TERMINAL_TIP_RESPONSE
      );
      resolve(res);
    } catch (ex: any) {
      console.log("Error in handled_terminal_tip_response: ", ex.message);
      reject({ message: ex.message });
    }
  });
}

export const PROCESS_CHARGE = gql`
  ${process_charge_res.FRAGMENT}
  mutation ($input: Addprocess_charge!) {
    process_charge(input: $input) {
      ...process_charge_res_Fields
    }
  }
`;

export function process_charge(input: {
  input: Addprocess_charge;
}): Promise<process_charge_res> {
  return new Promise<process_charge_res>(async (resolve, reject) => {
    try {
      let res = GraphqlService.SendMutation(PROCESS_CHARGE, input);
      resolve(res);
    } catch (ex: any) {
      console.log("Error in process_charge: ", ex.message);
      reject({ message: ex.message });
    }
  });
}
