import { gql } from '@apollo/client';
import {Transaction_invoice} from "../transaction_invoice/index";
import {dtres} from "../cardconnect_terminal/index";
import {itemnameSP} from "../cardconnect_terminal/index";
import {Invoice_item} from "../transaction_invoice/index";
import {itemInput} from "../cardconnect_terminal/index"

export class Cardconnect_transaction{
    id?: number; 
    retref: string = '';
    respcode: string = '';
    response: dtres = new dtres();
    amount: number = 0;
    items: itemnameSP[] = [];
    invoice_tiems: Invoice_item[] = []
    setlstat: string = '';
    type: string = '';
    name: string = '';
    time: string = '';   
    order_reference: string = '';
    void: boolean = false;
    transaction_invoice?: Transaction_invoice;

    static FRAGMENT = gql`
    fragment Cardconnect_transaction_Fields on Cardconnect_transaction {
        id
        retref
        respcode
        response{...dtres_Fields}
        amount
        items{...itemnameSP_Fields}
        invoice_tiems{...Invoice_item_Fields}
        setlstat
        type
        name
        time
        order_reference
        void
        transaction_invoice{...Transaction_invoice_Fields}
    }
    ${dtres.FRAGMENT}
    ${itemnameSP.FRAGMENT}
    ${Invoice_item.FRAGMENT()}
    ${Transaction_invoice.FRAGMENT()}
  `; 
}

export class miRes{
    success: boolean = false;
    message: string = '';

    static FRAGMENT = gql`
    fragment miRes_Fields on miRes {
        success
        message
    }
  `; 
}



export class batchResult{
    clearing_house: string = '';
    refundtotal: string = '';
    chargetotal: string = '';
    status: string = '';

    static FRAGMENT = gql`
    fragment batchResult_Fields on batchResult {
        clearing_house
        refundtotal
        chargetotal
        status
    }
  `; 
}



export class statusResponse{
    batch_result: batchResult = new batchResult()
    resptext: string = ''; 
    setlstat: string = ''; 
    respcode: string = ''; 
    refundable: string = ''; 
    mount: string = '';   
    capturedate: string = ''; 
    settledate: string = ''; 
    batchid: string = ''; 
    entrymode: string = ''; 
    authdate: string = ''; 
    lastfour: string = '';  
    name: string = '';

    static FRAGMENT = gql`
    fragment statusResponse_Fields on statusResponse {
        batch_result{...batchResult_Fields}
        resptext
        setlstat
        respcode
        refundable
        mount
        capturedate
        settledate
        batchid
        entrymode
        authdate
        lastfour
        name
    }
    ${batchResult.FRAGMENT}
  `; 
}





export class Addcardconnect_transaction{
    cardconnect_id?: number;
    retref: string = '';
    respcode: string = '';
    response: string = '';
    amount: number = 0;
    items: string = '';
    setlstats: string = '';
    type: string = '';
    name: string = '';
}

export class invoice_transaction{
    items: itemInput[] = []
    order_reference: string = '';
    email: string = '';
}

export class manual_charge{
    order_reference: string = '';
    items: itemInput[] = [];
    payinfo: Payinfo = new Payinfo();
}

export class Payinfo{
    number: string = '';
    cvv: string = '';
    exp: string = '';
    cardholder_name: string = '';
    street: string = '';
    city: string = '';
    state: string = '';
    zip: string = '';
}

export class online_payment{
    transaction_invoice_id?: number;
    email: string = '';
    phone: string = '';
    payinfo: Payinfo = new Payinfo();
}

export class ecommerceInput{
    token: string = '';
    items: itemInput[] = [];
    payinfo: Payinfo = new Payinfo();
    email: string = '';
    phone: string = '';
    amount: number = 0;
}

