import { gql } from '@apollo/client';

export class Business{
    id?: number;
    name: string = '';
    address: string = '';
    active: boolean = false;
    schema_id?: number;
    short_code: string = '';

    static FRAGMENT = gql`
    fragment Business_Fields on Business {
        id
        name
        address
        active
        schema_id
        short_code
    }
  `;
}

export class Business_profile{
    id?: number
    email: string = ''
    first_name: string = ''
    last_name: string = ''
    phone: string = ''
    verified: boolean = false

    static FRAGMENT = gql`
    fragment Business_profile_Fields on Business_profile {
        id
        email
        first_name
        last_name
        phone
        verified
    }
  `;
}

export class Business_settings{
    id?: number;
    receipt_text: string = '';
    invoice_text: string = '';

    static FRAGMENT = gql`
    fragment Business_settings_Fields on Business_settings {
        id
        receipt_text
        invoice_text
    }
  `;
}