import { gql } from "@apollo/client";
import { GraphqlService } from "../../graphql.service";
import { Cardconnect } from "./index";

export const CARDCONECT_BY_BUSINESSID = gql`
  ${Cardconnect.FRAGMENT}
  query ($businessId: Int!) {
    cardconect_by_businessId(businessId: $businessId) {
      ...Cardconnect_Fields
    }
  }
`;

export function cardconect_by_businessId(input: {
  businessId: number;
}): Promise<Cardconnect> {
  return new Promise<Cardconnect>(async (resolve, reject) => {
    try {
      let res = await GraphqlService.SendQuery(CARDCONECT_BY_BUSINESSID);
      resolve(res);
    } catch (ex: any) {
      console.log("Error in cardconect_by_businessId: ", ex.message);
      reject({ message: ex.message });
    }
  });
}
