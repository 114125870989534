import { gql } from '@apollo/client';

export class User{
    id?: number;
    name?: string = ''
    active?: boolean = true;

    static FRAGMENT = gql`
    fragment User_Fields on User {
        id
        name
        active
    }
  `;
}

export class Business_profile_type{
  id?: number;
  email: string = '';
  phone: string = '';
  verified: boolean = false;
  last_name: string = '';
  first_name: string = '';
  business_id?: number;

  constructor(x?: Business_profile_type){
    if(x){
      this.id = x.id;
      this.email = x.email;
      this.phone = x.phone;
      this.verified = x.verified;
      this.last_name = x.last_name;
      this.first_name = x.first_name;
      this.business_id = x.business_id;
    }
  }

  static FRAGMENT = gql`
    fragment Business_profile_type_Fields on Business_profile_type {
      id
      email
      phone
      verified
      last_name
      first_name
      business_id
    }
  `;
}

export class TokenClass{
  business_id?: number;
  name: string = '';
  business_profile: Business_profile_type = new Business_profile_type();

  constructor(x?: TokenClass){
    if(x){
      this.business_id = x.business_id
      this.name = x.name
      this.business_profile = new Business_profile_type(x.business_profile);
    }
  }

  static FRAGMENT = gql`
    fragment TokenClass_Fields on TokenClass {
      business_id
      name
      business_profile{
        ...Business_profile_type_Fields
      }
    }
    ${Business_profile_type.FRAGMENT}
  `;
}

export class Standar_res {
    id?: number;
    success?: boolean = false;
    message?: string = ''

    static FRAGMENT = gql`
    fragment Standar_res_Fields on Standar_res {
        id
        success
        message
    }
  `;
}

export class AuthRes{
    success: boolean = false;
    token: string = '';
    iframe?: string = '';

    static FRAGMENT = gql`
    fragment AuthRes_Fields on AuthRes {
        success
        token
        iframe
    }
  `;
}

