import { gql } from "@apollo/client";
import { GraphqlService } from "../../graphql.service";
import { miRes } from "../cardconnect_transaction";
import { Business, Business_profile, Business_settings } from "./index";

export const BUSINESSES = gql`
  ${Business.FRAGMENT}
  query {
    businesses {
      ...Business_Fields
    }
  }
`;

export function businesses(): Promise<Business[]> {
  return new Promise<Business[]>(async (resolve, reject) => {
    try {
      let res = await GraphqlService.SendQuery(BUSINESSES);
      resolve(res);
    } catch (ex: any) {
      console.log("Error in businesses: ", ex.message);
      reject({ message: ex.message });
    }
  });
}

export const AUTH = gql`
  query {
    auth
  }
`;

export function auth(): Promise<string> {
  return new Promise<string>(async (resolve, reject) => {
    try {
      let res = await GraphqlService.SendQuery(AUTH);
      resolve(res);
    } catch (ex: any) {
      console.log("Error in auth: ", ex.message);
      reject({ message: ex.message });
    }
  });
}

export const BUSINESS_PROFILE = gql`
  ${Business_profile.FRAGMENT}
  query {
    business_profile {
      ...Business_profile_Fields
    }
  }
`;

export function business_profile(): Promise<Business_profile> {
  return new Promise<Business_profile>(async (resolve, reject) => {
    try {
      let res = await GraphqlService.SendQuery(BUSINESS_PROFILE);
      resolve(res);
    } catch (ex: any) {
      console.log("Error in business_profile: ", ex.message);
      reject({ message: ex.message });
    }
  });
}

export const BUSINESS_SETTINGS = gql`
  ${Business_settings.FRAGMENT}
  query {
    business_settings {
      ...Business_settings_Fields
    }
  }
`;

export function business_settings(): Promise<Business_settings> {
  return new Promise<Business_settings>(async (resolve, reject) => {
    try {
      let res = await GraphqlService.SendQuery(BUSINESS_SETTINGS);
      resolve(res);
    } catch (ex: any) {
      console.log("Error in business_settings: ", ex.message);
      reject({ message: ex.message });
    }
  });
}

export const BUSINESS = gql`
  ${Business.FRAGMENT}
  query {
    business {
      ...Business_Fields
    }
  }
`;

export function business(): Promise<Business> {
  return new Promise<Business>(async (resolve, reject) => {
    try {
      let res = await GraphqlService.SendQuery(BUSINESS);
      resolve(res);
    } catch (ex: any) {
      console.log("Error in business: ", ex.message);
      reject({ message: ex.message });
    }
  });
}


export const VERIFY_PASSWORD_TOKEN = gql`
${miRes.FRAGMENT}
query ($token: String) {
  verify_password_token(token: $token) {
      ...miRes_Fields
    }
  }
`

export function verify_password_token(input:{token: String}): Promise<miRes> {
  return new Promise<miRes>(async (resolve, reject) => {
    try {
      let res = await GraphqlService.SendQuery(VERIFY_PASSWORD_TOKEN, input);
      resolve(res);
    } catch (ex: any) {
      console.log("Error in verify_password_token: ", ex.message);
      reject({ message: ex.message });
    }
  });
}
