import { gql } from "@apollo/client";
import { GraphqlService } from "../../graphql.service";
import { process_charge_res } from "../cardconnect_terminal/index";
import { online_payment, ecommerceInput } from "./index";

export const MANUAL_INPUT = gql`
  ${process_charge_res.FRAGMENT}
  mutation ($input: String) {
    manual_input(input: $input) {
      ...process_charge_res_Fields
    }
  }
`;

export function manual_input(input: String): Promise<process_charge_res> {
  return new Promise<process_charge_res>(async (resolve, reject) => {
    try {
      let res = await GraphqlService.SendMutation(MANUAL_INPUT, input);
      resolve(res);
    } catch (ex: any) {
      console.log("Error in manual_input: ", ex.message);
      reject({ message: ex.message });
    }
  });
}

export const CUSTOMER_PAYMENT = gql`
${process_charge_res.FRAGMENT}
mutation($input: online_payment){ 
    manual_input(input : $input){
        ...process_charge_res_Fields
    }
}
`;

export function customer_payment(input: {
  input: online_payment;
}): Promise<process_charge_res> {
  return new Promise<process_charge_res>(async (resolve, reject) => {
    try {
      let res = await GraphqlService.SendMutation(CUSTOMER_PAYMENT, input);
      resolve(res);
    } catch (ex: any) {
      console.log("Error in customer_payment: ", ex.message);
      reject({ message: ex.message });
    }
  });
}

export const ECOMMERCEPAYMENT = gql`
${process_charge_res.FRAGMENT}
mutation($input: String){ 
    manual_input(input : $input){
        ...process_charge_res_Fields
    }
}
`;

export function eCommercePayment(input: {
  input: String;
}): Promise<process_charge_res> {
  return new Promise<process_charge_res>(async (resolve, reject) => {
    try {
      let res = await GraphqlService.SendMutation(ECOMMERCEPAYMENT, input);
      resolve(res);
    } catch (ex: any) {
      console.log("Error in eCommercePayment: ", ex.message);
      reject({ message: ex.message });
    }
  });
}

export const RECURRINGPAYMENT = gql`
${process_charge_res.FRAGMENT}
mutation($input: ecommerceInput){ 
    manual_input(input : $input){
        ...process_charge_res_Fields
    }
}
`;

export function recurringPayment(input: {
  input: ecommerceInput;
}): Promise<process_charge_res> {
  return new Promise<process_charge_res>(async (resolve, reject) => {
    try {
      let res = await GraphqlService.SendMutation(RECURRINGPAYMENT, input);
      resolve(res);
    } catch (ex: any) {
      console.log("Error in recurringPayment: ", ex.message);
      reject({ message: ex.message });
    }
  });
}

export const VOID_TRANSACTION = gql`
${process_charge_res.FRAGMENT}
mutation($transactionId: Int){ 
    manual_input(transactionId : $transactionId){
        ...process_charge_res_Fields
    }
}
`;

export function void_transaction(input: {
  transactionId: number;
}): Promise<process_charge_res> {
  return new Promise<process_charge_res>(async (resolve, reject) => {
    try {
      let res = await GraphqlService.SendMutation(VOID_TRANSACTION, input);
      resolve(res);
    } catch (ex: any) {
      console.log("Error in void_transaction: ", ex.message);
      reject({ message: ex.message });
    }
  });
}

export const REFUND_TRANSACTION = gql`
${process_charge_res.FRAGMENT}
mutation($transactionId: Int, $amount: Float, $note: String){ 
    manual_input(transactionId : $transactionId, amount: $amount, note: $note){
        ...process_charge_res_Fields
    }
}
`;

export function refund_transaction(input: {
  transactionId: number;
  amount: number;
  note: String;
}): Promise<process_charge_res> {
  return new Promise<process_charge_res>(async (resolve, reject) => {
    try {
      let res = await GraphqlService.SendMutation(VOID_TRANSACTION, input);
      resolve(res);
    } catch (ex: any) {
      console.log("Error in refund_transaction: ", ex.message);
      reject({ message: ex.message });
    }
  });
}
