import moment from 'moment';
import momentTZ from 'moment-timezone';
import { NavigateFunction, Location } from 'react-router-dom';
import { GraphqlService } from '../services/GraphQL/graphql.service';
import { Queries } from '../services/GraphQL/queries';
import { AuthRes, TokenClass } from '../services/GraphQL/schemas/user';


interface INavigator
{
    push: (url: string) => void,
    goBack: () => void,
    location: {pathname: string}
}

export default class Helper  {

    static REDIRECT_LINK = '/';
    private static Navigator: NavigateFunction;
    private static Location: Location;

    static SetNavigator(navigator: NavigateFunction, location: Location)
    {
        this.Navigator = navigator;
        this.Location = location;
    }

    private static LoadingState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];

    static SetLoader(loadingState: [boolean, React.Dispatch<React.SetStateAction<boolean>>])
    {
        this.LoadingState = loadingState;
    }

    static Session = {
        User: new TokenClass(),
        SetLoading: (on: boolean) =>{
            this.LoadingState[1](on);
        },
        DoLogout: (redirect = true) => {
            localStorage.removeItem('profusepay');

            let url = (redirect ? Helper.Navigation.GetCurrentLocation() : '')
            if (url === '/')
                url = '';

            if (url.toLocaleLowerCase().startsWith('/login'))
                return;
                
            Helper.Navigation.NavigateTo('/login/' + url);

            localStorage.clear();
        },
        NewLogin: (data: AuthRes, path: string = '') => {
            localStorage.setItem('profusepay',data.token);
            GraphqlService.SetClient();
            this.Navigation.NavigateTo(path || '/invoices')
        },
        VerifyUser(): Promise<TokenClass>{
            return new Promise<TokenClass>(async (resolve, reject) =>{
                try {
                    let token = localStorage.getItem('profusepay');

                    if(!token){
                        throw new Error('Token not found in storage');
                    }

                    let user = await Queries.verify_user();
                   
                    this.User = new TokenClass(user);
                    resolve(user);
                   
                } catch (ex: any){
                    console.log('Error in VerifyUser: ' + ex.message);
                    reject({message: 'Not Authenticated'});
                }
            })
        }
    }


    static Navigation = {
        NavigateTo: (url: string) => {
            console.log('navigating to: ', url);
            Helper.Navigator(url)
        },
        // GoBack: () => {
        //     Helper.Navigator();
        // },
        GetCurrentLocation: () => {
            return Helper.Location.pathname;
        },
        NewTab: (url: string) => {
            Object.assign(document.createElement('a'), {
                target: '_blank',
                href: url,
            }).click();
        }
    }



    static FORMAT = {
        USCurrency: (value: number) => {
            if (isNaN(value) || value as any === '-0')
                value = 0;

            return new Intl.NumberFormat('en-US',
                    { style: 'currency', currency: 'USD' }
                  ).format(value)
        },
        ParseDate: (date: Date | string) => {
            return moment(date).format('YYYY/MM/DD')
        },
        ParseTime: (date: Date | string) => {
            return moment(date).format('HH:MM:SS')
        },
        USDate: (input: Date | string, formatType: 'n' | 'dd' | 'st' | 'do' | 'to' | 'ld' | 'ds' | 'totz' | 'd' | 'ns' = 'n', tz = Intl.DateTimeFormat().resolvedOptions().timeZone) => {
            let dateValue = new Date(input);
            if (!formatType)
                formatType = 'n';
            if (!dateValue)
                dateValue = new Date();

            if (!tz)
                tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
            

            const isToday = () => momentTZ(dateValue).tz(tz).format('YYYY/MM/DD') == momentTZ().tz(tz).format('YYYY/MM/DD');
            const getDaysDiff = () => {
                let diff = momentTZ(new Date(dateValue).setHours(0, 0, 0, 0)).tz(tz).diff(momentTZ(new Date().setHours(0, 0, 0, 0)).tz(tz), 'day');



                if (diff == -1)
                    return 'Yesterday';
                else if (diff == 1)
                    return 'Tomorrow';
                else if (diff == 0)
                    return 'Today'


                return diff < -1 ? `${-diff} days ago` : `In ${diff} days`;
            }



            const format = {
                'n': 'MM/DD/YYYY hh:mm A',
                'ns': 'MM/DD/YY hh:mm A',
                'dd': `(${isToday() ? 'Today' : `${getDaysDiff()}`})`,
                'st': (isToday() ? '' : 'MM/DD/YYYY ') + ' hh:mm A',
                'do': 'MM/DD/YYYY',
                'to': 'hh:mm A',
                'ld': 'dddd, MM/DD/YYYY',
                'ds': 'M/D',
                'totz': 'h:mm A',
                'd': 'MM/DD/YY'
            }


            const x = formatType == 'dd' ? format[formatType] : (moment(dateValue).tz(tz).format(format[formatType]) + (formatType == 'totz' ? ` (${new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2]})` : ''));
            return x;
        }
    }

    static Masks = {
        NoSpecialCharacters: (value: string, allowedChars: string = ' ', allowNumbers = true) => {
            let cleanText = 'abcdefghijklmnopqrstuvwxyz';
            if (allowNumbers)
                cleanText += '0123456789';

            cleanText += allowedChars;

            let text = '';
            let letters = value.split('');
            for(let i = 0; i < letters.length; i++){
                if (cleanText.includes(letters[i].toLowerCase())){
                    text += letters[i];
                }
            }
            return text;
        },
        Code: (value: string) => {
            return value
              .replace(/\d+/g, '')
              .replace(' ', '')
              .substring(0, 5)
              .toUpperCase();
        },
        USPhone: (value: string) => {
            
            let v = Helper.Masks.IntNumbers(value);
            if (v.length > 10)
                return v;
            let phone = '';
        let hcount = value.split('').filter(x => x == '-').length;
        
        value = value.replace('-', '');
        for (let i = 0; i < value.length; i++)
        {
            const char = value.substring(i, i + 1);

            if (char >= '0' && char <='9')
                phone += char;
            if (phone.length == 12)
                break;
            
            if (phone.length == 3 || phone.length == 7)
                phone += '-';
        }

        let array = phone.split('-');

        let first = array[0] || '';
        let second = array[1] || '';
        let third = array[2] || '';



        return first + ((hcount > 0 || second.length >= 1) && first.length > 2 ? '-' : '') + second + ((hcount > 1 || third.length >= 1) && second.length > 2 ? '-' : '') + third;
        },
        
        IntNumbers: (value: string, maxLength?: number) => {
            
            
            let negative = value.toString().split('').filter(x => x == '-').length == 1 ? '-':'';
            let newString = '';
            for (let i = value.length - 1; i >= 0 && (!maxLength || newString.length < maxLength); i--) {
                if('0123456789'.includes(value[i])){
                    newString = value[i] + newString;
                }
            }

            
            return newString ? (negative + (+newString).toString()) : '';
        },
        DecimalNumbers: (value: string, allowNegative: boolean = false) => {
                let negative = value.toString().split('').filter(x => x == '-').length == 1 ? '-':'';
                let newString = '';
                let decimalCount = 0;
                for (let i = 0; i < value.length; i++) {
                    const char = value.substring(i, i + 1);
                    if (((char >= '0' && char <= '9') || (char == '.' && !newString.includes('.'))) && decimalCount < 2)   
                    {
                        if (newString.includes('.'))
                            decimalCount++;
                        newString += char;
                    }
                    else if (i == 0 && char == '-' && allowNegative)
                        newString += char;
                    
                }
                newString = newString == '.' ? '0.' : newString;


                if (!newString)
                    return '0';
                const x = newString.split('.');

                return negative + (+x[0] || 0).toString() + (x.length > 1 ? '.' + x[1].toString() : '');
        },
        CreditCardExp: (value: string) => {
            const hasSlash = value.includes('/');
            value = value.trim().replace('/', '');
            let exp = '';
            
            for (let i = 0; i < value.length; i++)
            {
    
                
                const char = value.substring(i, i + 1);
    
                if (exp.length == 5)
                    break;
    
                if (exp.length == 2)
                {
                    exp += '/';
                    if (char == '/')
                        continue;
                }
                
                
                if (char >='0' && char <='9')
                    exp += char;
            }
    
            let month = exp.split('/')[0] || '';
            let year = exp.split('/')[1] || '';
    
            if (month.length == 1)
            {
                if (Number(month) > 1)
                    month = '';
            }
            else if (month.length == 2)
            {
                if (Number(month) == 0)
                    month = '0';
                if (Number(month) > 12)
                    month = '0' + month.substring(1, 2);
            }
    
    
    
    
            return month + (hasSlash || year.length >= 1 ? '/' : '') + year;
        },
        ValidUSMoney: (value: number, skip = false) => {
            return new Intl.NumberFormat('en-US',
                { style: 'currency', currency: 'USD' }
            ).format(value).replace('$', skip ? '' : '$').trim();
        },
        TimeFormat: (value: string) => {
            let time = '';
            let hcount = value.split('').filter(x => x == ':').length;
            value = value.replace(':', '');
            for (let i = 0; i < value.length; i++)
            {
                const char = value.substring(i, i + 1);
                
                if (time.length === 0  ) 
                {
                    if(char >= '0' && char <='1' ){
                    time += char;    
               
                    }
                }
               else if (time.length === 1 ) 
                    {
                        if(char >= '0' && char <='2' && value[0] === '1'){
                        time += char;    
                   
                        }else if(char >= '0' && char <='9' &&  value[0] === '0'){
                        time += char;
                        }
                    }

                else if (time.length === 3  ) 
                    {
                        if(char >= '0' && char <='5' ){
                        time += char;    
                   
                        }
                    }

                else if (time.length === 4  ) 
                    {
                        if(char >= '0' && char <='9' ){
                        time += char;    
                   
                        }
                    }

                 if (time.length == 5)
                    break;
                 if (time.length == 2  )
                    time += ':';
            }
            let array = time.split(':');
            let first = array[0] ;
            let second = array[1] || '';
    
            return first + ((hcount > 0 || second.length >= 1) && first.length >1  ? ':' : '') + second
      ;
        }
    
    }

    static Validators = {
        PasswordText: 'Password must be at least 7 characters long and contain at least one number, one uppercase letter, and one lowercase letter.',
        IsValidPassword: (password: string) => {
            // var regix = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{7,})");
            var regix = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{7,})");
            return regix.test(password);
        },
        IsValidEmail: (email: string) => {
            if (!email)
                return false;

            let emails = email.split(';').filter(x => x != '');
            
            
            for (let email of emails)
            {
                if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.trim()))
                    return false;
                
            }
            return true;
            
        },
        IsValidUSPhone: (phone: string) => {
            return Helper.Masks.USPhone(phone).length == 12;            
        },
        IsValidPhone: (phone: string) => {
            return Helper.Masks.IntNumbers(phone).length >= 10;
        },
        MinLength: (length: number) => {
            return length > 0;
        }
    }

    static getDepartments = () => {
        return [
            'DRIVER',
            'OFFICE',
            'WAREHOUSE'
        ];
    }

    static States = ['AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VA', 'VT', 'WA', 'WI', 'WV', 'WY'];
    
    static country  = ["Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antarctica", "Antigua and Barbuda",
    "Argentina", "Armenia", "Australia", "Austria", "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados",
    "Belarus", "Belgium", "Belize", "Benin", "Bermuda", "Bhutan", "Bolivia", "Bosnia and Herzegovina", "Botswana",
    "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burma", "Burundi", "Cambodia", "Cameroon", "Canada", "Cape Verde",
    "Central African Republic", "Chad", "Chile", "China", "Colombia", "Comoros", "Congo, Democratic Republic",
    "Congo, Republic of the", "Costa Rica", "Cote d'Ivoire", "Croatia", "Cuba", "Cyprus", "Czech Republic", "Denmark",
    "Djibouti", "Dominica", "Dominican Republic", "East Timor", "Ecuador", "Egypt", "El Salvador", "Equatorial Guinea",
    "Eritrea", "Estonia", "Ethiopia", "Fiji", "Finland", "France", "Gabon", "Gambia", "Georgia", "Germany", "Ghana",
    "Greece", "Greenland", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau", "Guyana", "Haiti", "Honduras", "Hong Kong",
    "Hungary", "Iceland", "India", "Indonesia", "Iran", "Iraq", "Ireland", "Israel", "Italy", "Jamaica", "Japan",
    "Jordan", "Kazakhstan", "Kenya", "Kiribati", "Korea, North", "Korea, South", "Kuwait", "Kyrgyzstan", "Laos", "Latvia",
    "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania", "Luxembourg", "Macedonia", "Madagascar",
    "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands", "Mauritania", "Mauritius", "Mexico", "Micronesia",
    "Moldova", "Mongolia", "Morocco", "Monaco", "Mozambique", "Namibia", "Nauru", "Nepal", "Netherlands", "New Zealand",
    "Nicaragua", "Niger", "Nigeria", "Norway", "Oman", "Pakistan", "Panama", "Papua New Guinea", "Paraguay", "Peru",
    "Philippines", "Poland", "Portugal", "Qatar", "Romania", "Russia", "Rwanda", "Samoa", "San Marino", " Sao Tome",
    "Saudi Arabia", "Senegal", "Serbia and Montenegro", "Seychelles", "Sierra Leone", "Singapore", "Slovakia", "Slovenia",
    "Solomon Islands", "Somalia", "South Africa", "Spain", "Sri Lanka", "Sudan", "Suriname", "Swaziland", "Sweden",
    "Switzerland", "Syria", "Taiwan", "Tajikistan", "Tanzania", "Thailand", "Togo", "Tonga", "Trinidad and Tobago",
    "Tunisia", "Turkey", "Turkmenistan", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom", "United States",
    "Uruguay", "Uzbekistan", "Vanuatu", "Venezuela", "Vietnam", "Yemen", "Zambia", "Zimbabwe"];
}
