import { gql } from "@apollo/client";
import { GraphqlService } from "../../graphql.service";
import {Charge_preset} from "./index"


export const GET_CHARGE_PRESET = gql`
${Charge_preset.FRAGMENT}
query {
    get_charge_preset{ 
        ...Cardonnect_refund_Fields
    }
}`

export function get_charge_preset(): Promise<Charge_preset[]>{
    return new Promise<Charge_preset[]>(async(resolve, reject) =>{
        try {
            let res = await GraphqlService.SendQuery(GET_CHARGE_PRESET);
            resolve(res);

        } catch (ex: any) {
            console.log('Error in get_charge_preset: ', ex.message);
            reject({message: ex.message});
        }
    })
}
