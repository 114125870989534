import { gql } from "@apollo/client";
import { GraphqlService } from "../../graphql.service";
import { Cardconnect_transaction, statusResponse } from "./index";

export const TRANSACTIONS_BY_CARDCONECTID = gql`
  ${Cardconnect_transaction.FRAGMENT}
  query ($cardconnectId: Int) {
    transactions_by_cardconectId(cardconnectId: $cardconnectId) {
      ...Cardconnect_transaction_Fields
    }
  }
`;

export function transactions_by_cardconectId(input: {
  cardconnectId: number;
}): Promise<Cardconnect_transaction> {
  return new Promise<Cardconnect_transaction>(async (resolve, reject) => {
    try {
      let res = await GraphqlService.SendQuery(
        TRANSACTIONS_BY_CARDCONECTID,
        input
      );
      resolve(res);
    } catch (ex: any) {
      console.log("Error in transactions_by_cardconectId: ", ex.message);
      reject({ message: ex.message });
    }
  });
}

export const CARDCONNECT_TRANSACTION_BY_BUSINESS = gql`
  ${Cardconnect_transaction.FRAGMENT}
  query ($start_date: String, $end_date: String) {
    cardconnect_transaction_by_business(
      start_date: $start_date
      end_date: $end_date
    ) {
      ...Cardconnect_transaction_Fields
    }
  }
`;

export function cardconnect_transaction_by_business(input: {
  start_date: String;
  end_date: String;
}): Promise<Cardconnect_transaction[]> {
  return new Promise<Cardconnect_transaction[]>(async (resolve, reject) => {
    try {
      let res = await GraphqlService.SendQuery(
        CARDCONNECT_TRANSACTION_BY_BUSINESS,
        input
      );
      resolve(res);
    } catch (ex: any) {
      console.log("Error in cardconnect_transaction_by_business: ", ex.message);
      reject({ message: ex.message });
    }
  });
}

export const GET_TRANSACTION_BY_ID = gql`
  ${Cardconnect_transaction.FRAGMENT}
  query ($id: Int!) {
    get_transaction_by_id(id: $id) {
      ...Cardconnect_transaction_Fields
    }
  }
`;

export function get_transaction_by_id(input: {
  id: number;
}): Promise<Cardconnect_transaction> {
  return new Promise<Cardconnect_transaction>(async (resolve, reject) => {
    try {
      let res = await GraphqlService.SendQuery(GET_TRANSACTION_BY_ID, input);
      resolve(res);
    } catch (ex: any) {
      console.log("Error in get_transaction_by_id: ", ex.message);
      reject({ message: ex.message });
    }
  });
}

export const GET_TRANSACTION_STATUS = gql`
  ${statusResponse.FRAGMENT}
  query ($id: Int) {
    get_transaction_status(id: $id) {
      ...statusResponse_Fields
    }
  }
`;

export function get_transaction_status(input: {
  id: number;
}): Promise<statusResponse> {
  return new Promise<statusResponse>(async (resolve, reject) => {
    try {
      let res = await GraphqlService.SendQuery(GET_TRANSACTION_STATUS, input);
      resolve(res);
    } catch (ex: any) {
      console.log("Error in get_transaction_status: ", ex.message);
      reject({ message: ex.message });
    }
  });
}
