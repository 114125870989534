import { gql } from "@apollo/client";
import { GraphqlService } from "../../graphql.service";
import { Charge_preset } from "./index";

export const CREATE_CHARGE_PRESET = gql`
${Charge_preset.FRAGMENT}
mutation ($name: String, $base_amount: Float, $tax_amount: Float){
    create_charge_preset(name: $name, base_amount: $base_amount, tax_amount: $tax_amount){
        ...Charge_preset_Fields
    }
}`;

export function create_charge_preset(input: {
  name: String;
  base_amount: number;
  tax_amount: number;
}): Promise<Charge_preset> {
  return new Promise<Charge_preset>(async (resolve, reject) => {
    try {
      let res = await GraphqlService.SendMutation(CREATE_CHARGE_PRESET, input);
      resolve(res);
    } catch (ex: any) {
      console.log("Error in create_charge_preset: ", ex.message);
      reject({ message: ex.message });
    }
  });
}

export const UPDATE_CHARGE_PRESET = gql`
  ${Charge_preset.FRAGMENT}
  mutation ($id: Int!, $name: String, $base_amount: Float, $tax_amount: Float) {
    update_charge_preset(
      id: $id
      name: $name
      base_amount: $base_amount
      tax_amount: $tax_amount
    ) {
      ...Charge_preset_Fields
    }
  }
`;

export function update_charge_preset(input: {
  id: number;
  name: String;
  base_amount: number;
  tax_amount: number;
}): Promise<boolean> {
  return new Promise<boolean>(async (resolve, reject) => {
    try {
      let res = await GraphqlService.SendMutation(UPDATE_CHARGE_PRESET, input);
      resolve(res);
    } catch (ex: any) {
      console.log("Error in update_charge_preset: ", ex.message);
      reject({ message: ex.message });
    }
  });
}

export const DELETE_CHARGE_PRESET = gql`
  mutation ($id: Int!) {
    delete_charge_preset(id: $id)
  }
`;

export function delete_charge_preset(input: {
  id: number;
}): Promise<boolean> {
  return new Promise<boolean>(async (resolve, reject) => {
    try {
      let res = GraphqlService.SendMutation(DELETE_CHARGE_PRESET, input);
      resolve(res);
    } catch (ex: any) {
      console.log("Error in delete_charge_preset: ", ex.message);
      reject({ message: ex.message });
    }
  });
}
