import React, { useEffect, useState } from "react";
import { Button, InputField } from "@profuse-studio/profuse-pay-uix";
import Helper from "../../utilities/Helper";
import loginLogo from "../../../assets/images/ProfusePay_FullLogo.svg";
import "./forgotPassword.scss";
import { Mutations } from "../../services/GraphQL/mutations";
import { Link } from "react-router-dom";
import { GridLoader } from "../../components/Spinner/Grid";

export const ForgotPassword = () => {
  const [email, setEmail] = useState<string>("");
  const [endMessage, setEndMessage] = useState<string>("");
  const [errorText, setErrorText] = useState<string>("");

  async function handlePasswordReset() {
    Helper.Session.SetLoading(true);
    try {
      if (!Helper.Validators.IsValidEmail(email)) {
        throw new Error("Invalid email");
      }
      let res = await Mutations.request_password_reset({ email: email });
      if (!res || !res.success) {
        throw new Error(res ? res.message : "No connection to the server");
      }
      setEndMessage(res.message);
    } catch (ex: any) {
      console.log("Error in handlePasswordReset: ", ex.message);
      setErrorText(ex.message);
    }
    Helper.Session.SetLoading(false);
  }

  function validEmail() {
    return Helper.Validators.IsValidEmail(email);
  }

  useEffect(() => {
    setErrorText("");
  }, [email]);

  return (
    <div className="forgotPassword-component">
      <div className="forgotPassword-container">
        <div
          className="login-logo"
          onClick={() => Helper.Navigation.NavigateTo("/login")}
        >
          <img src={loginLogo} className="forgotPassword-img" alt="logo" />
        </div>
        {endMessage ? (
          <div className="reset-successfully col-12">
            <p className="reset-successfully-text col-12">{endMessage}</p>
          </div>
        ) : (
          <div className="row col-12">
            <div className="row">
              <p className="font-for-title mb-2">Recover Password</p>
            </div>
            <div className="row col-12" style={{ position: "relative" }}>
              <InputField
                type="email"
                name="email"
                placeholder="Email"
                required
                value={email}
                onChange={(e) => setEmail(e)}
                label={"Email"}
                onEnter={() => handlePasswordReset()}
                isValidated={(text) => {
                  return !Helper.Validators.IsValidEmail(text)
                    ? "Invalid email"
                    : null;
                }}
              />
              <Link to="/login" className="login-forgot-password">
                Back to Login?
              </Link>
            </div>
            <p className="error-text mt-1 col-12">{errorText}</p>
            <div className="login-btn col-12 mt-2">
              <Button
                btnColor="primary"
                label="Reset Password"
                className="primary"
                type="fill"
                onClick={() => handlePasswordReset()}
                active={validEmail()}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
