import { gql } from "@apollo/client";
import { GraphqlService } from "../../graphql.service";
import {IdRes, MerchantMinimal_input, OwnerSiteUser_input, Merchant_input} from "./index";

export const CREATE_MERCHANT_MINIMAL = gql`
  ${IdRes.FRAGMENT}
  mutation ($merchant_minimal: MerchantMinimal_input!, $template_type: String!, $ownerSiteUser: OwnerSiteUser_input) {
    create_merchant_minimal(merchant_minimal: $merchant_minimal, template_type: $template_type, ownerSiteUser: $ownerSiteUser) {
      ...IdRes_Fields
    }
  }`;

export function create_merchant_minimal(input: {
  merchant_minimal: MerchantMinimal_input;
  template_type: String;
  ownerSiteUser: OwnerSiteUser_input;
}): Promise<IdRes> {
  return new Promise<IdRes>(async (resolve, reject) => {
    try {
      let res = await GraphqlService.SendMutation(
        CREATE_MERCHANT_MINIMAL,
        input
      );
      resolve(res);
    } catch (ex: any) {
      console.log("Error in create_merchant_minimal: ", ex.message);
      reject({ message: ex.message });
    }
  });
}

export const UPDATE_MERCHANT_APPLICATION = gql`
  ${IdRes.FRAGMENT}
  mutation (
    $merchantId: Int!
    $merchant: Merchant_input
    $ownerSiteUser: OwnerSiteUser_input
  ) {
    update_merchant_application(
      merchantId: $merchantId
      merchant: $merchant
      ownerSiteUser: $ownerSiteUser
    ) {
      ...IdRes_Fields
    }
  }
`;

export function update_merchant_application(input: {
  merchantId: number;
  merchant: Merchant_input;
  ownerSiteUser: OwnerSiteUser_input;
}): Promise<IdRes> {
  return new Promise<IdRes>(async (resolve, reject) => {
    try {
      let res = await GraphqlService.SendMutation(
        UPDATE_MERCHANT_APPLICATION,
        input
      );
      resolve(res);
    } catch (ex: any) {
      console.log("Error in update_merchant_application: ", ex.message);
      reject({ message: ex.message });
    }
  });
}

export const GET_MERCHANT_SIGNATUREURL = gql`
  mutation ($merchantId: Int) {
    get_merchant_signatureUrl(merchantId: $merchantId)
  }
`;

export function get_merchant_signatureUrl(input: {
  merchantId: number;
}): Promise<String> {
  return new Promise<String>(async (resolve, reject) => {
    try {
      let res = await GraphqlService.SendMutation(
        GET_MERCHANT_SIGNATUREURL,
        input
      );
      resolve(res);
    } catch (ex: any) {
      console.log("Error in get_merchant_signatureUrl: ", ex.message);
      reject({ message: ex.message });
    }
  });
}

export const RETRACT_MERCHANT_SIGNATUREURL = gql`
  ${IdRes.FRAGMENT}
  mutation ($merchantId: Int!) {
    retract_merchant_signatureUrl(merchantId: $merchantId) {
      ...IdRes_Fields
    }
  }
`;

export function retract_merchant_signatureUrl(input: {
  merchantId: number;
}): Promise<IdRes> {
  return new Promise<IdRes>(async (resolve, reject) => {
    try {
      let res = await GraphqlService.SendMutation(
        RETRACT_MERCHANT_SIGNATUREURL,
        input
      );
      resolve(res);
    } catch (ex: any) {
      console.log("Error in retract_merchant_signatureUrl: ", ex.message);
      reject({ message: ex.message });
    }
  });
}

export const UPDATE_OWNER_SITE_USER = gql`
  ${IdRes.FRAGMENT}
  mutation ($merchantId: Int, $ownerSiteUser: OwnerSiteUser_input) {
    update_owner_site_user(
      merchantId: $merchantId
      ownerSiteUser: $ownerSiteUser
    ) {
      ...IdRes_Fields
    }
  }
`;

export function update_owner_site_user(input: {
  merchantId: number;
  ownerSiteUser: OwnerSiteUser_input;
}): Promise<IdRes> {
  return new Promise<IdRes>(async (resolve, reject) => {
    try {
      let res = await GraphqlService.SendMutation(
        UPDATE_OWNER_SITE_USER,
        input
      );
      resolve(res);
    } catch (ex: any) {
      console.log("Error in update_owner_site_user: ", ex.message);
      reject({ message: ex.message });
    }
  });
}
