import { ApolloClient } from "apollo-client";

import { setContext } from "apollo-link-context";
import { createUploadLink } from "apollo-upload-client";

import crypto from 'crypto-js';

import { InMemoryCache } from "apollo-cache-inmemory";
import environments from "../environment/environment";
import {randomHexString} from '../app/services/ps-crypt'


const getPlatform = (token: string) => {
    let secret = token.substring(token.length - 8);
    let time = new Date().getTime().toString();
    return crypto.AES.encrypt(time, secret).toString();
}

const link = createUploadLink({
    uri: environments.SERVER_LINK + '/graphql'
  });

  const authLink = setContext((_, { headers }) => {
    let token = localStorage.getItem('profusepay') || randomHexString(8);
    return {
      headers: {
        ...headers,
        "Authentication": token,
        "Platform": getPlatform(token)
      }
    };
  });

export function createClient(){
    return new ApolloClient({
      link: authLink.concat(link as any),
      cache: new InMemoryCache({
        addTypename: false
      })
    });
};
