import { gql } from '@apollo/client';
import { User } from '.';
import { GraphqlService } from '../../graphql.service';

export const CREATE_USER = gql`
${User.FRAGMENT}
mutation ($name: String, $password: String){
    create_user(name: $name, password: $password){
        ...User_Fields
    }
}`;

export function create_user(input: {name: string, password: string}): Promise<User> {
    return new Promise<User>(async (resolve, reject) => {
        try {
            let res = await GraphqlService.SendMutation(CREATE_USER, input);
            resolve(res);
        } catch (ex: any) {
            console.log('Error in create_user: ', ex.message);
            reject({message: ex.message});
        }
    })
}

