const Distribution = true;

const IFRAME_STYLES = `%23ccnumfield%7Bwidth%3Acalc%28100%25-2.5rem%29%3Bpadding%3A.65rem%201.2rem%3Bborder%3A1px%20solid%20%23e5e5e5%3Boutline%3A0%3Btransition%3A.3s%20all%20ease%3Bfont-size%3Acalc%281rem%20-%204px%29%3Bborder-top-left-radius%3A8px%3Bborder-top-right-radius%3A8px%7Dbody%7Bmargin%3A0%3Bpadding%3A0%7D%23tokenform%7Boverflow%3Ahidden%7Dform%7Boverflow%3Ahidden%7D%23ccnumfield.error%7Bcolor%3Ared%21important%3Bborder-color%3Ared%21important%7D%23ccnumfield%3A%3Aplaceholder%7Bfont-size%3A16px%3Bcolor%3A%23ccc%7D%23ccnumfield%3Afocus%7Bborder-color%3A%23000%7D`;
const IFRAME_OPTIONS = '?invalidinputevent=true&invalidcreditcardevent=true&formatinput=true&placeholder=0000%200000%200000%200000&css=' + IFRAME_STYLES;

const environments = {
    prod: Distribution,
    IFRAME_OPTIONS,
    IFRAME_LINK : (Distribution ? 'https://boltgw.cardconnect.com/itoke/ajax-tokenizer.html' : 'https://boltgw-uat.cardconnect.com/itoke/ajax-tokenizer.html') + IFRAME_OPTIONS,
    
    SERVER_LINK: Distribution ? 'https://api.profusepay.com' : 'http://38.75.18.145:41001'//'http://192.168.10.9:41000'
}

export default environments 