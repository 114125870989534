import { gql } from '@apollo/client';
import {GraphqlService} from '../../graphql.service'
import { AuthRes, TokenClass } from '.';

export const AUTHENTICATE_USER = gql`
${AuthRes.FRAGMENT}
query ($data: String!, $stay_signed: Boolean!){
    authenticate_user(data: $data, stay_signed: $stay_signed){
    ...AuthRes_Fields
    }
}`;

export function authenticate_user(input:{data: string, stay_signed: boolean}): Promise<AuthRes>{
    return new Promise<AuthRes>(async(resolve, reject) =>{
        try {
            let res = await GraphqlService.SendQuery(AUTHENTICATE_USER, input);
            resolve(res);
        } catch (ex: any) {
            console.log('Error in authenticate_user: ', ex.message);
            reject({message: ex.message});
        }
    });
}

export const VERIFY_USER = gql`
${TokenClass.FRAGMENT}
query {
    verify_user{
        ...TokenClass_Fields
    }
}
`;

export function verify_user(): Promise<TokenClass>{
    return new Promise<TokenClass>(async(resolve, reject) =>{
        try {
            let res = await GraphqlService.SendQuery(VERIFY_USER);
            resolve(res);
        } catch (ex: any) {
            console.log('Error in verify_user: ', ex.message);
            reject({message: ex.message});
        }
    });
}

export const GET_WORD_OBJECT = gql`
query {
  get_word_object
}`

export function get_word_object(): Promise<string>{
    return new Promise<string>(async(resolve, reject) =>{
        try {
            let res = await GraphqlService.SendQuery(GET_WORD_OBJECT);
            resolve(res);

        } catch (ex: any) {
            console.log('Error in get_word_object: ', ex.message);
            reject({message: ex.message});
        }
    })
}