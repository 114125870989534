import { gql } from "@apollo/client";
import { GraphqlService } from "../../graphql.service";
import { OwnerSiteUser, Merchant } from "./index";

export const GET_OWNER_SITE_USER = gql`
  ${OwnerSiteUser.FRAGMENT}
  query ($merchantId: Int) {
    get_owner_site_user(merchantId: $merchantId) {
      ...OwnerSiteUser_Fields
    }
  }
`;

export function get_owner_site_user(input: {
  merchantId: number;
}): Promise<OwnerSiteUser> {
  return new Promise<OwnerSiteUser>(async (resolve, reject) => {
    try {
      let res = await GraphqlService.SendQuery(GET_OWNER_SITE_USER);
      resolve(res);
    } catch (ex: any) {
      console.log("Error in get_owner_site_user: ", ex.message);
      reject({ message: ex.message });
    }
  });
}

export const GET_MERCHANT_DATA = gql`
  ${Merchant.FRAGMENT}
  query ($merchantId: Int) {
    get_merchant_data(merchantId: $merchantId) {
      ...Merchant_Fields
    }
  }
`;

export function get_merchant_data(input: {
  merchantId: number;
}): Promise<Merchant> {
  return new Promise<Merchant>(async (resolve, reject) => {
    try {
      let res = await GraphqlService.SendQuery(GET_MERCHANT_DATA);
      resolve(res);
    } catch (ex: any) {
      console.log("Error in get_merchant_data: ", ex.message);
      reject({ message: ex.message });
    }
  });
}
