import React from 'react'
import { SearchField, Table, TableTabs } from "@profuse-studio/profuse-pay-uix";
import "../../../assets/scss/flexbox.scss"
import "./invoices.scss";
import { GridLoader } from '../../components/Spinner/Grid';

export const  Invoices = () => {


  
  return (
    <>
      <div className="row col-12 my-2">
        <div>
          <h4 className="invoice-title">Invoice</h4>
        </div>
        <div>
          <SearchField  background="#ffffff" onChange={() => {}} />
        </div>
      </div>
      <div className="invoce-tabs">
        {/* <GridLoader remSize={2} /> */}
        <TableTabs
        onSelect={function noRefCheck(){}}
        selected={0}
        tabs={[
          {
            index: 0,
            label: 'All (30)'
          },
          {
            index: 1,
            label: 'Open (10)'
          },
          {
            index: 2,
            label: 'Paid (20)'
          }
        ]}
        />
        <Table
          columns={[
            {
              flex: 2,
              label: 'DATE / TIME',
              name: 'date'
            },
            {
              flex: 2,
              label: 'TRANSACTION #',
              name: 'transaction_number'
            },
            {
              flex: 2,
              label: 'AMOUNT',
              name: 'amount',
              type: 'currency'
            },
            {
              flex: 3,
              label: 'PAYMENT METHOD',
              name: 'payment_method'
            },
            {
              flex: 3,
              label: 'PAID BY',
              name: 'paid_by'
            },
            {
              flex: 2,
              label: 'INVOICE NUMBER',
              name: 'invoice_number'
            },
            {
              flex: 2,
              label: 'STATUS',
              name: 'status',
              type: 'html'
            }
          ]}
          menuOptions={[]}
          rowHeight={80}
          rows={[
            {
              amount: 100,
              date: [
                '4/8/2021 4:03 pm',
                '(today)'
              ],
              invoice_number: '# 10000',
              paid_by: [
                'Paul Rand',
                'Private'
              ],
              payment_method: 'VISA *9999',
              status: '<div class="badge badge-paid">Paid</div>',
              transaction_number: '1234567890'
            },
            {
              amount: 400,
              date: [
                '5/8/2021 4:03 pm',
                '(today)'
              ],
              invoice_number: '# 10001',
              paid_by: [
                'Paul Rand',
                'Private'
              ],
              payment_method: 'VISA *9999',
              status: '<div class="badge badge-past-due">Paid</div>',
              transaction_number: '1234567891'
            },
            {
              amount: 200,
              date: [
                '6/8/2021 4:03 pm',
                '(today)'
              ],
              invoice_number: '# 10002',
              paid_by: [
                'Paul Rand',
                'Private'
              ],
              payment_method: 'VISA *9999',
              status: '<div class="badge-alt badge-invoiced">Paid</div>',
              transaction_number: '1234567892'
            }
          ]}
          showCheckbox
          tableHeight="90vh"
        />
      </div>
    </>

  )
}
 