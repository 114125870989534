import { gql } from "@apollo/client";
import { GraphqlService } from "../../graphql.service";
import { Transaction_invoice } from "./index";

export const GET_TRANSACTION_INVOICE = gql`
  ${Transaction_invoice.FRAGMENT()}
  query ($token: String) {
    get_transaction_invoice(token: $token) {
      ...Transaction_invoice_Fields
    }
  }
`;

export function get_transaction_invoice(input: {
  token: String;
}): Promise<Transaction_invoice> {
  return new Promise<Transaction_invoice>(async (resolve, reject) => {
    try {
      let res = await GraphqlService.SendQuery(GET_TRANSACTION_INVOICE);
      resolve(res);
    } catch (ex: any) {
      console.log("Error in get_transaction_invoice: ", ex.message);
      reject({ message: ex.message });
    }
  });
}

export const GET_TRANSACTION_INVOICE_BY_BUSINESS = gql`
  ${Transaction_invoice.FRAGMENT()}
  query ($start_date: String, $end_date: String) {
    get_transaction_invoice_by_business(
      start_date: $start_date
      end_date: $end_date
    ) {
      ...Transaction_invoice_Fields
    }
  }
`;

export function get_transaction_invoice_by_business(input: {
  start_date: String;
  end_date: String;
}): Promise<Transaction_invoice[]> {
  return new Promise<Transaction_invoice[]>(async (resolve, reject) => {
    try {
      let res = await GraphqlService.SendQuery(
        GET_TRANSACTION_INVOICE_BY_BUSINESS
      );
      resolve(res);
    } catch (ex: any) {
      console.log("Error in get_transaction_invoice_by_business: ", ex.message);
      reject({ message: ex.message });
    }
  });
}
