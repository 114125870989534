import { gql } from "@apollo/client";
import { GraphqlService } from "../../graphql.service";
import {
  Transaction_invoice,
  Add_transaction_invoice,
  resend_transaction_input,
} from "./index";
import { miRes } from "../cardconnect_transaction/index";

export const CREATE_TRANSACTION_INVOICE = gql`
  ${Transaction_invoice.FRAGMENT()}
  mutation ($input: Add_transaction_invoice) {
    create_transaction_invoice(input: $input) {
      ...Transaction_invoice_Fields
    }
  }
`;

export function create_transaction_invoice(input: {
  input: Add_transaction_invoice;
}): Promise<Transaction_invoice> {
  return new Promise<Transaction_invoice>(async (resolve, reject) => {
    try {
      let res = GraphqlService.SendMutation(CREATE_TRANSACTION_INVOICE, input);
      resolve(res);
    } catch (ex: any) {
      console.log("Error in create_transaction_invoice: ", ex.message);
      reject({ message: ex.message });
    }
  });
}

export const RESEND_RECEIPT = gql`
  ${miRes.FRAGMENT}
  mutation ($email: String, $phone: String, $transaction_id: Int!) {
    resend_receipt(
      email: $email
      phone: $phone
      transaction_id: $transaction_id
    ) {
      ...miRes_Fields
    }
  }
`;

export function resend_receipt(input: {
  email: String;
  phone: String;
  transaction_id: number;
}): Promise<miRes> {
  return new Promise<miRes>(async (resolve, reject) => {
    try {
      let res = await GraphqlService.SendMutation(RESEND_RECEIPT, input);
      resolve(res);
    } catch (ex: any) {
      console.log("Error in resend_receipt: ", ex.message);
      reject({ message: ex.message });
    }
  });
}

export const RESEND_TRANSACTION_INVOICE = gql`
${miRes.FRAGMENT}
mutation($input: resend_transaction_input){
  resend_transaction_invoice(input: $input){
    ...miRes_Fields
  }
}
`;

export function resend_transaction_invoice(input: {
  input: resend_transaction_input;
}): Promise<miRes> {
  return new Promise<miRes>(async (resolve, reject) => {
    try {
      let res = await GraphqlService.SendMutation(
        RESEND_TRANSACTION_INVOICE,
        input
      );
      resolve(res);
    } catch (ex: any) {
      console.log("Error in resend_transaction_invoice:", ex.message);
      reject({ message: ex.message });
    }
  });
}

export const DELETE_INVOICE = gql`
${miRes.FRAGMENT}
mutation($invoice_id: Int){
  delete_invoice(invoice_id: $invoice_id){
    ...miRes_Fields
  }
}`;

export function delete_invoice(input: { invoice_id: number }): Promise<miRes> {
  return new Promise<miRes>(async (resolve, reject) => {
    try {
      let res = await GraphqlService.SendMutation(DELETE_INVOICE, input);
      resolve(res);
    } catch (ex: any) {
      console.log("Error in delete_invoice: ", ex.message);
      reject({ message: ex.message });
    }
  });
}

export const CREATE_UNIQUE_TRANSACTION_INVOICE = gql`
${Transaction_invoice.FRAGMENT()}
mutation($input: Add_transaction_invoice){
  create_unique_transaction_invoice(input: $input){
    ...Transaction_invoice_Fields
  }
}`;

export function create_unique_transaction_invoice(input: {
  input: Add_transaction_invoice;
}): Promise<Transaction_invoice> {
  return new Promise<Transaction_invoice>(async (resolve, reject) => {
    try {
      let res = await GraphqlService.SendMutation(
        CREATE_UNIQUE_TRANSACTION_INVOICE,
        input
      );
      resolve(res);
    } catch (ex: any) {
      console.log("Error in create_unique_transaction_invoice: ", ex.message);
      reject({ message: ex.message });
    }
  });
}

export const SEND_TEXT_MESSAGE = gql`
  mutation ($phone_number: String, $message: String) {
    send_text_message(phone_number: $phone_number, message: $message)
  }`;

export function send_text_message(input: {
  phone_number: String;
  message: String;
}): Promise<boolean> {
  return new Promise<boolean>(async (resolve, reject) => {
    try {
      let res = await GraphqlService.SendMutation(SEND_TEXT_MESSAGE, input);
      resolve(res);
    } catch (ex: any) {
      console.log("Error in send_text_message: ", ex.message);
      reject({ message: ex.message });
    }
  });
}
