import "./grid.scss";

export const GridLoader = ({remSize}:{remSize: number} = {remSize: 1}) => {
    const REM = remSize/2;
    const SIZE = remSize + 'rem'
  return (<div className="loading-screen">
    <div className="lds-grid" style={{
        width: (5 * remSize) + 'rem',
        height: (5 * remSize) + 'rem'
        }}>
      <div style={{
          width: SIZE,
          height: SIZE,
          top: (REM * 1) + 'rem',
          left: (REM * 1) + 'rem'
          }}></div>
      <div style={{
          width: SIZE,
          height: SIZE,
          top: (REM * 1) + 'rem',
          left: (REM * 4) + 'rem'
          }}></div>
      <div style={{
          width: SIZE,
          height: SIZE,
          top: (REM * 1) + 'rem',
          left: (REM * 7) + 'rem'
          }}></div>
      <div style={{
          width: SIZE,
          height: SIZE,
          top: (REM * 4) + 'rem',
          left: (REM * 1) + 'rem'
          }}></div>
      <div style={{
          width: SIZE,
          height: SIZE,
          top: (REM * 4) + 'rem',
          left: (REM * 4) + 'rem'
          }}></div>
      <div style={{
          width: SIZE,
          height: SIZE,
          top: (REM * 4) + 'rem',
          left: (REM * 7) + 'rem'
          }}></div>
      <div style={{
          width: SIZE,
          height: SIZE,
          top: (REM * 7) + 'rem',
          left: (REM * 1) + 'rem'
          }}></div>
      <div style={{
          width: SIZE,
          height: SIZE,
          top: (REM * 7) + 'rem',
          left: (REM * 4) + 'rem'
          }}></div>
      <div style={{
          width: SIZE,
          height: SIZE,
          top: (REM * 7) + 'rem',
          left: (REM * 7) + 'rem'
          }}></div>
    </div>
  </div>);
};
