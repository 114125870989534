import React, { useState, useEffect } from "react";
import Helper from "../../utilities/Helper";
import { Queries } from "../../services/GraphQL/queries/index";
import { Button, InputField } from "@profuse-studio/profuse-pay-uix";
import hidePasswordImg from "../../../assets/images/Icon_eye_closed.png";
import showPasswordImg from "../../../assets/images/Icon_eye_open.png";
import loginLogo from "../../../assets/images/ProfusePay_FullLogo.svg";
import "./passwordReset.scss";
import { useParams } from "react-router-dom";
import { encrypt } from "../../services/ps-crypt";
import { Mutations } from "../../services/GraphQL/mutations";

export const PasswordReset = () => {
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>("");
  const [failedText, setFailedText] = useState<string>("");
  const [messageText, setMessageText] = useState<string>("");

  let token = window.location.href.split('/passwordreset/')[1];
  localStorage.setItem("profusepay", token)
  const data = () => {
    return encrypt({password: newPassword, confirm_password: confirmNewPassword});
  }


  async function handlePasswordReset() {
    try {
      if(newPassword !== confirmNewPassword){
        setErrorText("The password and confirmation password do not match.")
        return
      }
      let res = await Mutations.change_forgot_password({ token: token, data: data() });
      if (!res || !res.success) {
        throw new Error("No connection to the server");
      } else{
        Helper.Session.NewLogin(res);
      }

      setErrorText("");
    } catch (ex: any) {
      console.log("Error in handlePasswordReset: ", ex.message);
      setErrorText(ex.message);
    }
  }

  function validPasswords() {
    return (
      Helper.Validators.IsValidPassword(newPassword) &&
      Helper.Validators.IsValidPassword(confirmNewPassword)
    );
  }

  async function CheckToken() {
    Helper.Session.SetLoading(true);
    try {
      let res = await Queries.verify_password_token({token});
      if(!res || !res.success){
        throw new Error(res ? res.message : 'No connection to the server')
      }
      setMessageText(res.message);
    } catch (ex: any) {
      if(ex.message == '404'){
        Helper.Navigation.NavigateTo('/404');
      }
      else{
        setFailedText(ex.message);
      }
      // Helper.Navigation.NavigateTo("/login");
    }
    Helper.Session.SetLoading(false);
 }

  useEffect(() => {
    CheckToken();
  }, [])

  return (
    <div className="resetPassword-component">
      <div className="resetPassword-container">
        <div className="logo">
          <img src={loginLogo} className="resetPassword-img" alt="logo" />
        </div>
        {messageText && <div className="row">
          <p className="font-for-title mb-2">{messageText}</p>
          <div className="col-12 mb-1-5" style={{ position: "relative" }}>
            <InputField
              type={showPassword ? "text" : "password"}
              name="New Password"
              placeholder="New Password"
              required
              onChange={(e) => setNewPassword(e)}
              label="New Password"
              onEnter={() => handlePasswordReset()}
              isValidated={(text) => {
                return !Helper.Validators.IsValidPassword(text)
                  ? "Password must be at least 7 characters long and contain at least one number, one uppercase letter, and one lowercase letter"
                  : null;
              }}
            />
            <img
              src={showPassword ? showPasswordImg : hidePasswordImg}
              className="login-password-input-toggle"
              alt="Login"
              onClick={(e) => setShowPassword(!showPassword)}
            />
          </div>
          <div className="col-12" style={{ position: "relative" }}>
            <InputField
              type="password"
              name="Confirm Password"
              placeholder="Confirm Password"
              required
              onChange={(e) => setConfirmNewPassword(e)}
              label="Confirm Password"
              onEnter={() => handlePasswordReset()}
              isValidated={(text) => {
                return !Helper.Validators.IsValidPassword(text)
                  ? "Password must be at least 7 characters long and contain at least one number, one uppercase letter, and one lowercase letter"
                  : null;
              }}
            />
          </div>
          <p className="error-text mt-1 col-12">{errorText}</p>
          <div className="login-btn col-12 mt-2">
            <Button
              className={`primary`}
              onClick={() => handlePasswordReset()}
              label="Reset Password"
              type="fill"
              active={validPasswords()}
              btnColor={"primary"}
            />
          </div>
        </div>}
        {failedText && <p className="font-for-title mb-2">{failedText}</p>}
      </div>
    </div>
  );
};
